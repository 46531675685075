import React, {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {PurchaseOrderPage} from './PurchaseOrderPage'

const PurchaseOrderPageWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Purchase Order</PageTitle>      
      <PurchaseOrderPage className='mb-5 mb-xl-8' />
    </>
  )
}

export default PurchaseOrderPageWrapper
