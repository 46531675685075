import React, {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import { UserPage } from './UserPage'

const UserPageWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Users Data</PageTitle>      
      <UserPage className='mb-5 mb-xl-8' />
    </>
  )
}

export default UserPageWrapper
