/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useState, useEffect } from 'react';
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { PageTitle } from '../../../_metronic/layout/core'
import {
  StatisticsWidget5,
  ChartsWidget1,
  ChartsWidget2,
  ChartsWidget3,
  ChartsWidget4
} from '../../../_metronic/partials/widgets'
import { Row } from 'react-bootstrap'
import axios from 'axios';
import $, { data } from 'jquery';
import 'datatables.net-dt';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import 'datatables.net-fixedcolumns'
import { Button, Modal } from 'react-bootstrap';
import { Logout,AuthPage, useAuth } from '../../../app/modules/auth'

type Props = {
  className: string
}

const DashboardPage: React.FC<Props> = ({ className }) => {

  const { auth, logout } = useAuth()

  interface Item {
    no_group: string;
    group_name: string;
    status: number;
    no_account: number;
    group_account: number;
    group_type: string;
    type_sales: number;
    wip_account: number;
    default_fg: boolean;
    default_rm: boolean;
    default_wip: boolean;
    jenis: string;
  }

  const API_URL = process.env.REACT_APP_THEME_API_URL
  const GET_PO_DATA = `${API_URL}/Monitoring`
  const GET_PO_BYID = `${API_URL}/PurchaseOrder/getbyid`
  const [items, setItems] = useState<Array<Item>>([]);
  const [showModal, setShowModal] = useState(false);
  const { currentUser, setCurrentUser } = useAuth()


  const [DashboardData, setDashboardData] = useState({
    onprocess: '',
    delay: '',
    pending: '',
    done: '',
  });


  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleShowModal = (name: any) => {
    console.log(`Hello, ${name}!`);
    setShowModal(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${GET_PO_DATA}`);
        const jsonData = response.data.data;
        setItems(jsonData);
        const onprocess = jsonData.filter((item: any) => item.status1 === 1);
        const delay = jsonData.filter((item: any) => item.status1 === 5);
        const pending = jsonData.filter((item: any) => item.status1 === 4);
        const done = jsonData.filter((item: any) => item.status1 === 2);

        const totalonprocess = onprocess.length;
        const totaldelay = delay.length;
        const totalpending = pending.length;
        const totaldone = done.length;

        const updateData = () => {
          setDashboardData({
            onprocess: totalonprocess.toString(),
            delay: totaldelay.toString(),
            pending: totalpending.toString(),
            done: totaldone.toString(),
          });
        };
        setDashboardData({
          onprocess: totalonprocess.toString(),
          delay: totaldelay.toString(),
          pending: totalpending.toString(),
          done: totaldone.toString(),
        });

      } catch (error) {
        logout();
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  return (
    <div>
      <>
        <div className='row g-5 g-xl-8'>
          <div className='col-xl-3'>
            <StatisticsWidget5
              className='card-xl-stretch mb-xl-8'
              svgIcon='/media/icons/duotune/general/gen032.svg'
              color='primary'
              iconColor='white'
              title={DashboardData.onprocess}
              titleColor='white'
              description='ON PROCESS'
              descriptionColor='white'
            />
          </div>
          <div className='col-xl-3'>
            <StatisticsWidget5
              className='card-xl-stretch mb-xl-8'
              svgIcon='/media/icons/duotune/ecommerce/ecm008.svg'
              color='success'
              iconColor='white'
              title={DashboardData.delay}
              titleColor='white'
              description='DELAY'
              descriptionColor='white'
            />
          </div>
          <div className='col-xl-3'>
            <StatisticsWidget5
              className='card-xl-stretch mb-xl-8'
              svgIcon='/media/icons/duotune/finance/fin006.svg'
              color='warning'
              iconColor='white'
              title={DashboardData.pending}
              titleColor='white'
              description='PENDING'
              descriptionColor='white'
            />
          </div>
          <div className='col-xl-3'>
            <StatisticsWidget5
              className='card-xl-stretch mb-5 mb-xl-8'
              svgIcon='/media/icons/duotune/graphs/gra007.svg'
              color='info'
              iconColor='white'
              title={DashboardData.done}
              titleColor='white'
              description='DONE'
              descriptionColor='white'
            />
          </div>
        </div>
        <div className='row g-5 g-xl-8'>
          <div className='col-xl-6'>
            <ChartsWidget1 className='card-xl-stretch mb-xl-8' />
          </div>
          <div className='col-xl-6'>
            <ChartsWidget2 className='card-xl-stretch mb-5 mb-xl-8' />
          </div>
        </div>
      </>
    </div>
  )
}


const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <DashboardPage className='mb-5 mb-xl-8' />
    </>
  )
}

export { DashboardWrapper }
