import React, {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import { RolePage } from './RolePage'

const RolePageWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Role</PageTitle>      
      <RolePage className='mb-5 mb-xl-8' />
    </>
  )
}

export default RolePageWrapper
