import React, {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {CustomerPage} from './CustomerPage'

const CustomerPageWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Master Customer</PageTitle>
      <CustomerPage className='mb-5 mb-xl-8' />
    </>
  )
}

export default CustomerPageWrapper
