import React, { useState, useEffect } from 'react';
import axios from 'axios';
import $ from 'jquery';
import 'datatables.net-dt';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import 'datatables.net-fixedcolumns'
import { Button, Modal } from 'react-bootstrap';

type Props = {
  className: string
}

const UserPage: React.FC<Props> = ({ className }) => {


  interface Item {
    no_group: string;
    group_name: string;
    status: number;
    no_account: number;
    group_account: number;
    group_type: string;
    type_sales: number;
    wip_account: number;
    default_fg: boolean;
    default_rm: boolean;
    default_wip: boolean;
    jenis: string;
  }


  
  const API_URL = process.env.REACT_APP_THEME_API_URL
  const GET_INV_GROUP = `${API_URL}/user`
  const GET_USERS_BY_ID = `${API_URL}/user/getbyid`
  const [items, setItems] = useState<Array<Item>>([]);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    id: '',
    org_id: '',
    username: '',
    fullname: '',
    email: ''    
  });

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleShowModal = (name: any) => {
    console.log(`Hello, ${name}!`);
    setShowModal(true);
  };

  useEffect(() => {

     // Handle button click event
     $('#myTable').on('click', 'button', function () {

      const iddata = this.id;
      const rowData = $(this).closest('tr').find('td').map(function () {
        return $(this).text();
      }).get();

      const rowId = $(this).closest('tr').attr('id');      
      handleClick(rowData, iddata);
    });

    const fetchData = async () => {
      try {
        const response = await axios.get(`${GET_INV_GROUP}`);
        const jsonData = response.data.data;
        setItems(jsonData);
        $('#myTable').DataTable({
          data: jsonData,
          columns: [
            { title: 'Username', data: 'username' },
            { title: 'FullName', data: 'fullname' },
            { title: 'Email', data: 'email' },
            { title: 'Organization Id', data: 'org_id' },
            { title: 'Action', data: null },
          ],
          info: true,
          paging: true,
          pageLength: 10,
          scrollCollapse: true,
          fixedColumns: {
            left: 1
          },
          columnDefs: [
            {
              targets: 4,
              render: function (data, type, full, meta) {
                if (type === 'display') {
                  data =
                  '<button class="btn btn-icon btn-primary btn-sm" id="' + data.id + '" type="button"><i class="fa fa-search" aria-hidden="true"></i></button>';
                }
                return data;
              }
            }
          ]
        });

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  const handleClick = async (rowData: any, rowId: any) => {
    console.log('Button clicked:', rowData, rowId);
    // Perform custom logic here based on the clicked row data or rowId    

    const GET_CUSTOMER_BYID_DATA = `${GET_USERS_BY_ID}?id=` + rowId
    const response = await axios.get(`${GET_CUSTOMER_BYID_DATA}`);
    const dataUsers = response.data;  
    console.log(dataUsers.data);  
    setFormData(dataUsers.data);
    console.log(formData)
    setShowModal(true);
  };

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}      
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        

        <div>
          <table id="myTable" className="table table-hover table-striped table-bordered">
            <thead>
              <tr>
                <th>Username</th>
                <th>Full Name</th>
                <th>Email</th>
                <th>Organization Id</th>
                <th>Action</th>
              </tr>
            </thead>
          </table>
        </div>

      </div>
      {/* begin::Body */}


      <Modal show={showModal} onHide={handleCloseModal} className='modal-lg'>
        <Modal.Header closeButton>
          <Modal.Title>Data Users</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-6">
              <div className="mb-3">
                <label className="form-label">Email</label>
                <input
                  type="text"
                  className="form-control form-control-white form-control-sm"
                  placeholder="Email"
                  name='email'
                  id='email'
                  value={formData.email}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Username</label>
                <input
                  type="text"
                  className="form-control form-control-white form-control-sm"
                  placeholder="Username"
                  id='username'
                  value={formData.username}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Nama Lengkap</label>
                <input
                  type="text"
                  className="form-control form-control-white form-control-sm"
                  placeholder="Nama Lengkap"
                  id='fullname'
                  value={formData.fullname}
                />
              </div>                           
            </div>           
          </div>          
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Update
          </Button>
          {/* <Button variant="primary" onClick={handleCloseModal}>
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export { UserPage }
