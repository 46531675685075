import React, { useState, useEffect } from 'react';
import axios from 'axios';
import $ from 'jquery';
import 'datatables.net-dt';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import 'datatables.net-fixedcolumns'
import { Button, Modal } from 'react-bootstrap';

type Props = {
  className: string
}

const ItemPage: React.FC<Props> = ({ className }) => {


  interface Item {
    no_group: string;
    group_name: string;
    status: number;
    no_account: number;
    group_account: number;
    group_type: string;
    type_sales: number;
    wip_account: number;
    default_fg: boolean;
    default_rm: boolean;
    default_wip: boolean;
    jenis: string;
  }


  const API_URL = process.env.REACT_APP_THEME_API_URL
  const GET_INV_GROUP = `${API_URL}/inventory`
  const GET_INV_BYID = `${API_URL}/inventory/getbynoitem`
  const [items, setItems] = useState<Array<Item>>([]);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    no_item: '',
    item_name: '',
    merk: '',
    serial_supplier: '',
    uom: '',
    uom_purchase: '',
    uom_konversi: '',
    min_stock: '',
    max_stock: '',
    status_item: '',
    ppn: '',
    markup: '',
    price_in: '',
    barcode: '',
    rop: '',
    rol: '',
    no_account: '',
    store_id: '',
    no_group: '',
    manufacture: '',
    fix_cost: '',
    avg_cost: '',
    last_cost: '',
    status: '',
    lead_time_days: '',
    partner_id: '',
    note: '',
    curr_id: '',
    internal_name: '',
    tracking_code: '',
    uom_sales: '',
    lot_size: '',
    last_counting_period_update: '',
    last_date_modified: '',
    categ_id: '',
    sl_no: '',
    default_sales_charge: '',
    tax_group: '',
    exp_date: '',
    margin: '',
    discount_procentage: '',
    discount_value: '',
    type_sales: '',
    uom_group: '',
    eoq: '',
    org_id: '',
    cost_per_item: '',
    map_code: '',
    category_partner_id: '',
    bin_code: '',
    class_id: '',
    generik: '',
    psikotropika: '',
    narkotika: '',
    obat_bebas: '',
    konsinyasi: '',
    dos: '',
    discount: '',
    item_parameter: '',
    item_type: '',
    bom_reff: '',
    scrap_type: '',
    bm_procentage: '',
    item_source: '',
    tax_group2: '',
    hs_code: '',
  });

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleShowModal = (name: any) => {
    console.log(`Hello, ${name}!`);
    setShowModal(true);
  };


  useEffect(() => {
    // Handle button click event
    $('#myTable').on('click', 'button', function () {

      const iddata = this.id;
      const rowData = $(this).closest('tr').find('td').map(function () {
        return $(this).text();
      }).get();

      const rowId = $(this).closest('tr').attr('id');
      // Call your custom onClick handler with row data or rowId
      handleClick(rowData, iddata);
    });

    const fetchData = async () => {
      try {
        const response = await axios.get(`${GET_INV_GROUP}`);
        const jsonData = response.data.data;
        setItems(jsonData);
        console.log(jsonData);
        $('#myTable').DataTable({
          info: true,
          paging: true,
          pageLength: 10,
          scrollCollapse: true,
          fixedColumns: {
            left: 1
          },
          data: jsonData,
          columns: [
            { title: 'No Item', data: 'no_item' },
            { title: 'Item Name', data: 'item_name' },
            { title: 'Category', data: 'categ_id' },
            { title: 'Unit', data: 'discount' },
            { title: 'Disc(%)', data: 'discount' },
            { title: 'PPH', data: 'discount' },
            { title: 'PPN', data: 'ppn' },
            { title: 'Min Stock', data: 'min_stock' },
            { title: 'Max Stock', data: 'max_stock' },
            { title: 'Lead Time', data: 'lead_time_days' },
            { title: 'Action', data: null },
          ],
          columnDefs: [
            {
              targets: 10,
              render: function (data, type, full, meta) {
                if (type === 'display') {
                  data =
                    '<button class="btn btn-icon btn-primary btn-sm" id="' + data.no_item + '" type="button"><i class="fa fa-search" aria-hidden="true"></i></button>';
                }
                return data;
              }
            }
          ]
        });

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  const handleClick = async (rowData: any, rowId: any) => {
    console.log('Button clicked:', rowData, rowId);
    // Perform custom logic here based on the clicked row data or rowId    

    const GET_INV_BYID_DATA = `${GET_INV_BYID}?no_item=` + rowId
    const response = await axios.get(`${GET_INV_BYID_DATA}`);
    const dataSupplier = response.data;
    console.log(dataSupplier.data);
    setFormData(dataSupplier.data);
    console.log(formData)
    setShowModal(true);
  };

  return (
    <div>

      <div className={`card ${className}`}>
        {/* begin::Header */}
        {/* <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Master Supplier</span>
        </h3>
        <div className='card-toolbar'>
        </div>
      </div> */}
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          <div>
            <table id="myTable" className="table table-hover table-rounded table-striped border gy-4 gs-7">
              <thead>
                <tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
                  <th>No Item</th>
                  <th>Item Name</th>
                  <th>Category</th>
                  <th>Unit</th>
                  <th>Disc(%)</th>
                  <th>PPH</th>
                  <th>PPN</th>
                  <th>Min Stock</th>
                  <th>Max Stock</th>
                  <th>Lead Time</th>
                  <th>Action</th>
                </tr>
              </thead>
            </table>
          </div>

        </div>
        {/* begin::Body */}

      </div>
      <Modal show={showModal} onHide={handleCloseModal} className='modal-lg'>
        <Modal.Header closeButton>
          <Modal.Title>Inventory Detail Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-6">
              <div className="mb-3">
                <label className="form-label">Item Code</label>
                <input
                  type="text"
                  className="form-control form-control-white form-control-sm form-control-sm"
                  placeholder="Item Code"
                  name='no_item'
                  id='no_item'
                  value={formData.no_item} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Item Name</label>
                <input
                  type="text"
                  className="form-control form-control-white form-control-sm form-control-sm"
                  placeholder="Item Name"
                  name='item_name'
                  id='item_name'
                  value={formData.item_name} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Principle</label>
                <input
                  type="text"
                  className="form-control form-control-white form-control-sm form-control-sm"
                  placeholder="Principle"
                  name='item_name'
                  id='item_name'
                  value={formData.item_name} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Group</label>
                <input
                  type="text"
                  className="form-control form-control-white form-control-sm form-control-sm"
                  placeholder="Group"
                  name='no_group'
                  id='no_group'
                  value={formData.no_group} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Category</label>
                <input
                  type="text"
                  className="form-control form-control-white form-control-sm form-control-sm"
                  placeholder="Category"
                  name='categ_id'
                  id='categ_id'
                  value={formData.categ_id} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Classification</label>
                <input
                  type="text"
                  className="form-control form-control-white form-control-sm form-control-sm"
                  placeholder="Classification"
                  name='class_id'
                  id='class_id'
                  value={formData.class_id} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Item Location</label>
                <input
                  type="text"
                  className="form-control form-control-white form-control-sm form-control-sm"
                  placeholder="Item Location"
                  name='map_code'
                  id='map_code'
                  value={formData.map_code} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Vendor Name</label>
                <input
                  type="text"
                  className="form-control form-control-white form-control-sm form-control-sm"
                  placeholder="Vendor Name"
                  name='partner_id'
                  id='partner_id'
                  value={formData.partner_id} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Serial / Lot Tracking</label>
                <input
                  type="text"
                  className="form-control form-control-white form-control-sm form-control-sm"
                  placeholder="Serial / Lot Tracking"
                  name='tracking_code'
                  id='tracking_code'
                  value={formData.tracking_code} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Status</label>
                <input
                  type="text"
                  className="form-control form-control-white form-control-sm form-control-sm"
                  placeholder="Status"
                  name='status'
                  id='status'
                  value={formData.status} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Inventory Type</label>
                <input
                  type="text"
                  className="form-control form-control-white form-control-sm form-control-sm"
                  placeholder="Inventory Type"
                  name='scrap_type'
                  id='scrap_type'
                  value={formData.scrap_type} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Bin Locator</label>
                <input
                  type="text"
                  className="form-control form-control-white form-control-sm form-control-sm"
                  placeholder="Bin Locator"
                  name='scrap_type'
                  id='scrap_type'
                  value={formData.scrap_type} disabled
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="mb-3">
                <label className="form-label">Level Unit</label>
                <input
                  type="text"
                  className="form-control form-control-white form-control-sm form-control-sm"
                  placeholder="Bin Locator"
                  name='scrap_type'
                  id='scrap_type'
                  value={formData.scrap_type} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Purchase Unit</label>
                <input
                  type="text"
                  className="form-control form-control-white form-control-sm form-control-sm"
                  placeholder="Bin Locator"
                  name='scrap_type'
                  id='scrap_type'
                  value={formData.scrap_type} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Sales Unit</label>
                <input
                  type="text"
                  className="form-control form-control-white form-control-sm form-control-sm"
                  placeholder="Bin Locator"
                  name='scrap_type'
                  id='scrap_type'
                  value={formData.scrap_type} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Unit Cost</label>
                <input
                  type="text"
                  className="form-control form-control-white form-control-sm form-control-sm"
                  placeholder="Bin Locator"
                  name='scrap_type'
                  id='scrap_type'
                  value={formData.scrap_type} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Price / Unit</label>
                <input
                  type="text"
                  className="form-control form-control-white form-control-sm form-control-sm"
                  placeholder="Bin Locator"
                  name='scrap_type'
                  id='scrap_type'
                  value={formData.scrap_type} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Discount</label>
                <input
                  type="text"
                  className="form-control form-control-white form-control-sm form-control-sm"
                  placeholder="Bin Locator"
                  name='scrap_type'
                  id='scrap_type'
                  value={formData.scrap_type} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Tax Group</label>
                <input
                  type="text"
                  className="form-control form-control-white form-control-sm form-control-sm"
                  placeholder="Bin Locator"
                  name='scrap_type'
                  id='scrap_type'
                  value={formData.scrap_type} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Currency</label>
                <input
                  type="text"
                  className="form-control form-control-white form-control-sm form-control-sm"
                  placeholder="Bin Locator"
                  name='scrap_type'
                  id='scrap_type'
                  value={formData.scrap_type} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Min Stock</label>
                <input
                  type="text"
                  className="form-control form-control-white form-control-sm form-control-sm"
                  placeholder="Bin Locator"
                  name='scrap_type'
                  id='scrap_type'
                  value={formData.scrap_type} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Max Stock</label>
                <input
                  type="text"
                  className="form-control form-control-white form-control-sm form-control-sm"
                  placeholder="Bin Locator"
                  name='scrap_type'
                  id='scrap_type'
                  value={formData.scrap_type} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Total Reorder</label>
                <input
                  type="text"
                  className="form-control form-control-white form-control-sm form-control-sm"
                  placeholder="Bin Locator"
                  name='scrap_type'
                  id='scrap_type'
                  value={formData.scrap_type} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Reorder Level</label>
                <input
                  type="text"
                  className="form-control form-control-white form-control-sm form-control-sm"
                  placeholder="Bin Locator"
                  name='scrap_type'
                  id='scrap_type'
                  value={formData.scrap_type} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Lead Time</label>
                <input
                  type="text"
                  className="form-control form-control-white form-control-sm form-control-sm"
                  placeholder="Bin Locator"
                  name='scrap_type'
                  id='scrap_type'
                  value={formData.scrap_type} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Last Update</label>
                <input
                  type="text"
                  className="form-control form-control-white form-control-sm form-control-sm"
                  placeholder="Bin Locator"
                  name='scrap_type'
                  id='scrap_type'
                  value={formData.scrap_type} disabled
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          {/* <Button variant="primary" onClick={handleCloseModal}>
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export { ItemPage }
