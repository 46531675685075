import React, {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {ItemPage} from './ItemPage'

const ItemPageWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Master Inventory</PageTitle>      
      <ItemPage className='mb-5 mb-xl-8' />
    </>
  )
}

export default ItemPageWrapper
