import React, { useState, useEffect } from 'react';
import axios from 'axios';
import $ from 'jquery';
import 'datatables.net-dt';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import 'datatables.net-fixedcolumns';

type Props = {
  className: string
}

const UserRolePage: React.FC<Props> = ({ className }) => {
  const API_URL = process.env.REACT_APP_THEME_API_URL
  const GET_INV_GROUP = `${API_URL}/UserRole`

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${GET_INV_GROUP}`);
        const jsonData = response.data.data;
        $('#myTable').DataTable({
          data: jsonData,          
          columns: [
            { title: 'Id', data: 'id' },
            { title: 'Role Name', data: 'role_name' },
            { title: 'Action', data: null },
          ],          
          info: true,
          paging: true,
          pageLength: 15,                   
          columnDefs: [
            {
              targets: 2,
              render: function (data, type, full, meta) {
                if (type === 'display') {
                  data =
                    '<button class="btn btn-info btn-sm" data-dismiss="modal" id="Edit" type="button">Show</button>';
                }
                return data;
              }
            }
          ]
        });

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className='card-body py-3'>
        <div>
          <table id="myTable" className="display table table-hover table-striped table-bordered">
            <thead>
              <tr>
                <th>Id</th>
                <th>Role Name</th>
                <th>Action</th>
              </tr>
            </thead>
          </table>
        </div>
      </div>
    </div>


  )
}

export { UserRolePage }
