import React, { useState, useEffect } from 'react';
import axios from 'axios';
import $ from 'jquery';
import 'datatables.net-dt';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import 'datatables.net-fixedcolumns'
import { Button, Modal } from 'react-bootstrap';

type Props = {
  className: string
}

const PurchaseRequestPage: React.FC<Props> = ({ className }) => {


  interface Item {
    no_group: string;
    group_name: string;
    status: number;
    no_account: number;
    group_account: number;
    group_type: string;
    type_sales: number;
    wip_account: number;
    default_fg: boolean;
    default_rm: boolean;
    default_wip: boolean;
    jenis: string;
  }


  const API_URL = process.env.REACT_APP_THEME_API_URL
  const GET_PR_DATA = `${API_URL}/PurchaseRequest`
  const GET_PR_DATA_BYID = `${API_URL}/PurchaseRequest/getbyid`
  const [items, setItems] = useState<Array<Item>>([]);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    spp_id: '',
    org_id: '',
    org_desc: '',
    org_entity: '',
    spp_date: '',
    note: '',
    ordered_by: '',
    status: '',
    kode_dept: '',
    empid: '',
    approved_by: '',
    date_approved: '',
    date_required: '',
    pic_id: '',
    no_item: '',
    item_name: '',
    po: '',
    date_order: '',
    person: '',
    qty_order: '',
    partner_id: '',
    partner_name: '',
    bd: '',
    cat: '',
    haz: '',
    partial: '',
  });

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleShowModal = (name: any) => {
    console.log(`Hello, ${name}!`);
    setShowModal(true);
  };


  useEffect(() => {
    $('#myTable').on('click', 'button', function () {
      const iddata = this.id;
      const rowData = $(this).closest('tr').find('td').map(function () {
        return $(this).text();
      }).get();
      const rowId = $(this).closest('tr').attr('id');
      handleClick(rowData, iddata);
    });

    const fetchData = async () => {
      try {
        const response = await axios.get(`${GET_PR_DATA}`);
        const jsonData = response.data.data;
        setItems(jsonData);
        $('#myTable').DataTable({
          info: true,
          paging: true,
          pageLength: 10,
          scrollCollapse: true,
          fixedColumns: {
            right: 2
          },
          scrollX:true,
          data: jsonData,
          columns: [
            { title: 'PR Input', data: 'spp_date' },
            { title: 'PO Date', data: 'date_order' },
            { title: 'Number PO', data: 'po' },
            { title: 'Nama PT', data: 'partner_name' },
            { title: 'Sales', data: 'person' },
            { title: 'Cat Number', data: 'cat' },
            { title: 'Description', data: 'item_name' },
            { title: 'Jumlah', data: 'qty_order' },
            { title: 'Order To Stock', data: 'qty_order' },
            { title: 'Order', data: 'qty_order' },
            { title: 'HAZ/NON', data: 'haz' },
            { title: 'Partial/Full', data: 'partial' },
            { title: 'BD', data: 'bd' },
            { title: 'Notes(No SDR)', data: 'note' },
            { title: 'Notes(Procurement)', data: 'note' },
            { title: 'Action', data: null },
          ],
          columnDefs: [
            {
              targets: 15,
              render: function (data, type, full, meta) {
                if (type === 'display') {
                  data =
                    '<button class="btn btn-icon btn-primary btn-sm" id="' + data.spp_id + '" type="button"><i class="fa fa-search" aria-hidden="true"></i></button>';
                }
                return data;
              }
            }
          ]
        });

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  const handleClick = async (rowData: any, rowId: any) => {
    const GET_INV_BYID_DATA = `${GET_PR_DATA_BYID}?spp_id=` + rowId
    const response = await axios.get(`${GET_INV_BYID_DATA}`);
    const dataSupplier = response.data;
    setFormData(dataSupplier.data);
    setShowModal(true);
  };

  return (
    <div>
      <div className={`card ${className}`}>
        <div className='card-body py-3'>
          <div>
            <table id="myTable" className="table table-rounded table-striped border gy-7 gs-7">
              <thead>
                <tr className="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
                  <th>PR Input</th>
                  <th>PO Date</th>
                  <th>Number PO</th>
                  <th>Nama PT</th>
                  <th>Sales</th>
                  <th>Cat Number</th>
                  <th>Description</th>
                  <th>Jumlah</th>
                  <th>Order To Stock</th>
                  <th>Order</th>
                  <th>HAZ/NON</th>
                  <th>Partial/Full</th>
                  <th>BD</th>
                  <th>Notes(No SDR)</th>
                  <th>Notes(Procurement)</th>
                  <th>Action</th>
                </tr>
              </thead>
            </table>
          </div>

        </div>
        {/* begin::Body */}

      </div>
      <Modal show={showModal} onHide={handleCloseModal} className='modal-lg'>
        <Modal.Header closeButton>
          <Modal.Title>Purchase Request Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-6">
              <div className="mb-3">
                <label className="form-label">PR Input</label>
                <input
                  type="text"
                  className="form-control form-control-white"
                  placeholder="PR Input"
                  name='spp_id'
                  id='spp_id'
                  value={formData.spp_id} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">PO Date</label>
                <input
                  type="text"
                  className="form-control form-control-white"
                  placeholder="PO Date"
                  name='date_order'
                  id='date_order'
                  value={formData.date_order} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Nomor PO</label>
                <input
                  type="text"
                  className="form-control form-control-white"
                  placeholder="Nomor PO"
                  name='po'
                  id='po'
                  value={formData.po} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Nomor PO</label>
                <input
                  type="text"
                  className="form-control form-control-white"
                  placeholder="Nomor PO"
                  name='po'
                  id='po'
                  value={formData.po} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Nama PT</label>
                <input
                  type="text"
                  className="form-control form-control-white"
                  placeholder="Nama PT"
                  name='org_entity'
                  id='org_entity'
                  value={formData.org_entity} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Sales</label>
                <input
                  type="text"
                  className="form-control form-control-white"
                  placeholder="Sales"
                  name='person'
                  id='person'
                  value={formData.person} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Cat. Number</label>
                <input
                  type="text"
                  className="form-control form-control-white"
                  placeholder="Cat. Number"
                  name='cat'
                  id='cat'
                  value={formData.cat} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Description</label>
                <input
                  type="text"
                  className="form-control form-control-white"
                  placeholder="Description"
                  name='item_name'
                  id='item_name'
                  value={formData.item_name} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Jumlah</label>
                <input
                  type="text"
                  className="form-control form-control-white"
                  placeholder="Jumlah"
                  name='qty_order'
                  id='qty_order'
                  value={formData.qty_order} disabled
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="mb-3">
                <label className="form-label">Order To Stock</label>
                <input
                  type="text"
                  className="form-control form-control-white"
                  placeholder="Order To Stock"
                  name='qty_order'
                  id='qty_order'
                  value={formData.qty_order} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Order</label>
                <input
                  type="text"
                  className="form-control form-control-white"
                  placeholder="Order"
                  name='qty_order'
                  id='qty_order'
                  value={formData.qty_order} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Haz/Non</label>
                <input
                  type="text"
                  className="form-control form-control-white"
                  placeholder="Haz/Non"
                  name='haz'
                  id='haz'
                  value={formData.haz} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Partial/Full</label>
                <input
                  type="text"
                  className="form-control form-control-white"
                  placeholder="Partial/Full"
                  name='partial'
                  id='partial'
                  value={formData.partial} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">BD</label>
                <input
                  type="text"
                  className="form-control form-control-white"
                  placeholder="BD"
                  name='bd'
                  id='bd'
                  value={formData.bd} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Notes SDR</label>
                <input
                  type="text"
                  className="form-control form-control-white"
                  placeholder="Notes SDR"
                  name='note'
                  id='note'
                  value={formData.note} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Notes Procurement</label>
                <input
                  type="text"
                  className="form-control form-control-white"
                  placeholder="Notes Procurement"
                  name='note'
                  id='note'
                  value={formData.note} disabled
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>


  )
}

export { PurchaseRequestPage }
