import React, {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import { CategoryPage } from './CategoryPage'

const CategoryPageWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Master Item</PageTitle>      
      <CategoryPage className='mb-5 mb-xl-8' />
    </>
  )
}

export default CategoryPageWrapper
