import React, {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import { MenuPage } from './MenuPage'

const MenuPageWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Menu</PageTitle>      
      <MenuPage className='mb-5 mb-xl-8' />
    </>
  )
}

export default MenuPageWrapper
