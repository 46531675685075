import React, {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {MonitoringPage} from './MonitoringPage'

const MonitoringPageWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Monitoring</PageTitle>      
      <MonitoringPage className='mb-5 mb-xl-8' />
    </>
  )
}

export default MonitoringPageWrapper
