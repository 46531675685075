import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import PurchaseOrderPageWrapper from '../pages/purchaseorder/PurchaseOrderWrapper'
import PurchaseRequestPageWrapper from '../pages/purchaserequest/PurchaseRequestWrapper'
import CustomerPageWrapper from '../pages/customer/CustomerPageWrapper'
import SupplierPageWrapper from '../pages/supplier/SupplierPageWrapper'
import ItemPageWrapper from '../pages/item/ItemPageWrapper'
import UpdateStatusPageWrapper from '../pages/updatestatus/UpdateStatusPageWrapper'
import MonitoringPageWrapper from '../pages/monitoring/MonitoringPageWrapper'
import GoodReceivedPageWrapper from '../pages/goodreceived/GoodReceivedPageWrapper'
import CategoryPageWrapper from '../pages/category/CategoryPageWrapper'
import UserPageWrapper from '../pages/users/UserPageWrapper'
import RolePageWrapper from '../pages/role/RolePageWrapper'
import MenuPageWrapper from '../pages/menu/MenuPageWrapper'
import UserRolePageWrapper from '../pages/UserRole/UserRolePageWrapper'
import UserPermissionPageWrapper from '../pages/userspermission/UserPermissionPageWrapper'
import ProfilePageWrapper from '../pages/profile/ProfilePageWrapper'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='purchaseorder' element={<PurchaseOrderPageWrapper />} />
        <Route path='purchaserequest' element={<PurchaseRequestPageWrapper />} />
        <Route path='mastercustomer' element={<CustomerPageWrapper />} />
        <Route path='mastersupplier' element={<SupplierPageWrapper />} />
        <Route path='masteritem' element={<ItemPageWrapper />} />
        <Route path='updatestatus' element={<UpdateStatusPageWrapper />} />
        <Route path='monitoring' element={<MonitoringPageWrapper />} />
        <Route path='goodreceived' element={<GoodReceivedPageWrapper />} />
        <Route path='categoryitem' element={<CategoryPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        <Route path='usercore' element={<UserPageWrapper />} />
        <Route path='role' element={<RolePageWrapper />} />
        <Route path='menu' element={<MenuPageWrapper />} />
        <Route path='userrole' element={<UserRolePageWrapper />} />
        <Route path='permission' element={<UserPermissionPageWrapper />} />
        <Route path='profile' element={<ProfilePageWrapper />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
