import React, {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {UpdateStatusPage} from './UpdateStatusPage'

const UpdateStatusPageWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Update Status</PageTitle>      
      <UpdateStatusPage className='mb-5 mb-xl-8' />
    </>
  )
}

export default UpdateStatusPageWrapper
