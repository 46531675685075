import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import $ from 'jquery';
import 'datatables.net-dt';
import 'datatables.net-responsive-dt';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-responsive-dt/css/responsive.dataTables.min.css';
import 'datatables.net-fixedcolumns';
import { Button, Modal } from 'react-bootstrap';

type Props = {
  className: string
}

const UpdateStatusPage: React.FC<Props> = ({ className }) => {
  interface Item {
    no_group: string;
    group_name: string;
    status: number;
    no_account: number;
    group_account: number;
    group_type: string;
    type_sales: number;
    wip_account: number;
    default_fg: boolean;
    default_rm: boolean;
    default_wip: boolean;
    jenis: string;
  }

  interface OrderStatus {
    status_id: string;
    status_name: string;
  }

  interface OrderPosition {
    id: number;
    pos_name: string;
  }

  interface OrderProgress {
    id: number;
    prog_name: string;
  }


  const API_URL = process.env.REACT_APP_THEME_API_URL
  const UPDATE_STATUS_API = `${API_URL}/Monitoring/updatestatus`
  const CREATE_HISTORY_API = `${API_URL}/UpdateHistory`
  const GET_INV_GROUP = `${API_URL}/Monitoring`
  const GET_INV_BYID = `${API_URL}/Monitoring/getbyid`
  const GET_ORD_STATUS = `${API_URL}/TrackerStatus`
  const GET_ORD_POSITIONS = `${API_URL}/Position`
  const GET_ORD_PROGRESS = `${API_URL}/Progress`
  const [items, setItems] = useState<Array<Item>>([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedOrderStatus, setSelectedOrderStatus] = useState('');
  const [selectedOrderPosition, setSelectedOrderPositions] = useState(0);
  const [selectedOrderProgress, setSelectedOrderProgress] = useState(0);
  const [orderstatuses, setOrderStatuses] = useState<OrderStatus[]>([]);
  const [orderpositions, setOrderPositions] = useState<OrderPosition[]>([]);
  const [orderprogreses, setOrderProgreses] = useState<OrderProgress[]>([]);
  const [formData, setFormData] = useState({
    date_order: '',
    order_id: '',
    partner_id: '',
    partner_name: '',
    vendor_po_no: '',
    seq_no: 0,
    no_item: '',
    item_name: '',
    customer_po: '',
    partner_company: '',
    person: '',
    promo_code: '',
    order_type: '',
    sales_user: '',
    qty_po: 0,
    bd: '',
    status: 0,
    status_po: '',
    type_trans: '',
    type_trans_order: '',
    approved_by: '',
    periode: 0,
    qty_vendor: 0,
    act_qty: 0,
    qty_receive: 0,
    updated_date: '',
    status1: 0,
    status_item: '',
    vendor_origin_date: '',
    schedule_date: '',
    progress: 0,
    position: 0,
    pos_name: '',
    prog_name: '',
    order_notes1: '',
    order_notes2: '',
    order_notes3: '',
    uom: '',
    order_price: 0,
    tax_procentage: 0,
    curr_id: '',
    rate: 0,
    subtotal: 0,
    so_number: '',
    org_id: ''
  });


  const [inputValue, setInputValue] = useState('');
  const [qty_vendor, setqty_vendor] = useState('');
  const [act_qty, setact_qty] = useState('');
  const [vendor_origin_date, setvendor_origin_date] = useState('');
  const [schedule_date, setschedule_date] = useState('');
  const [order_notes1, setorder_notes1] = useState('');
  const [order_notes2, setorder_notes2] = useState('');
  const [order_notes3, setorder_notes3] = useState('');
  const [daygap, setdaygap] = useState(0);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const fetchOrderStatuses = async () => {
    try {
      // Fetch data from API
      const response = await axios.get(`${GET_ORD_STATUS}`);
      const data = await response.data.data;

      // Transform API response to Company objects
      const StatusData: OrderStatus[] = data.map((item: any) => ({
        status_id: item.status_id,
        status_name: item.status_name
      }));

      // Update the companies state with transformed data
      setOrderStatuses(StatusData);
    } catch (error) {
      console.error('Error fetching Order Status:', error);
    }
  };

  const fetchOrderPositions = async () => {
    try {
      // Fetch data from API
      const response = await axios.get(`${GET_ORD_POSITIONS}`);
      const data = await response.data.data;

      // Transform API response to Company objects
      const PositionData: OrderPosition[] = data.map((item: any) => ({
        id: item.id,
        pos_name: item.pos_name
      }));

      // Update the companies state with transformed data
      setOrderPositions(PositionData);
    } catch (error) {
      console.error('Error fetching Order Status:', error);
    }
  };

  const fetchOrderProgreses = async () => {
    try {
      // Fetch data from API
      const response = await axios.get(`${GET_ORD_PROGRESS}`);
      const data = await response.data.data;

      // Transform API response to Company objects
      const ProgressData: OrderProgress[] = data.map((item: any) => ({
        id: item.id,
        prog_name: item.prog_name
      }));

      // Update the companies state with transformed data
      setOrderProgreses(ProgressData);
    } catch (error) {
      console.error('Error fetching Order Status:', error);
    }
  };

  const handleOrderStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOrderStatus(e.target.value);
  };
  const handleOrderPositionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOrderPositions(parseInt(e.target.value));
  };
  const handleOrderProgressChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOrderProgress(parseInt(e.target.value));
  };
  const handleChange = (event: any) => {
    setInputValue(event.target.value);
  };

  const handleChangeqty_vendor = (event: any) => {
    setqty_vendor(event.target.value);
  };

  const handleChangeact_qty = (event: any) => {
    setact_qty(event.target.value);
  };
  const handleChangevendor_origin_date = (event: any) => {
    setvendor_origin_date(event.target.value);

    var sch_date = "";
    const schedule_date = document.getElementById('schedule_date') as HTMLInputElement;
    if (schedule_date) {
      sch_date = schedule_date.value;
    }

    var startDate = new Date(sch_date);
    var endDate = new Date(event.target.value);
    var timeDifferenceInMilliseconds = endDate.getTime() - startDate.getTime();

    var millisecondsInADay = 24 * 60 * 60 * 1000;
    var daysDifference = Math.floor(timeDifferenceInMilliseconds / millisecondsInADay);

    if (isNaN(daysDifference)) {
      setdaygap(0);
    } else {
      setdaygap(daysDifference);
    }
  };
  const handleChangeschedule_date = (event: any) => {
    setschedule_date(event.target.value);
    var ven_org_date = "";

    const vendor_origin_date = document.getElementById('vendor_origin_date') as HTMLInputElement;
    if (vendor_origin_date) {
      ven_org_date = vendor_origin_date.value;
    }

    var startDate = new Date(event.target.value);
    var endDate = new Date(ven_org_date);
    var timeDifferenceInMilliseconds = endDate.getTime() - startDate.getTime();

    var millisecondsInADay = 24 * 60 * 60 * 1000;
    var daysDifference = Math.floor(timeDifferenceInMilliseconds / millisecondsInADay);

    if (isNaN(daysDifference)) {
      setdaygap(0);
    } else {
      setdaygap(daysDifference);
    }

  };
  const handleChangesorder_notes1 = (event: any) => {
    setorder_notes1(event.target.value);
  };
  const handleChangesorder_notes2 = (event: any) => {
    setorder_notes2(event.target.value);
  };
  const handleChangesorder_notes3 = (event: any) => {
    setorder_notes3(event.target.value);
  };
  const handleRefresh = () => {
    window.location.reload();
  };

  const inputRef = useRef(null);

  const UpdateStatus = async () => {
    try {
      var status = selectedOrderStatus;
      var progress = selectedOrderProgress;
      var position = selectedOrderPosition;

      const notes = inputValue;
      const order_id = formData.order_id;
      const no_item = formData.no_item;
      const org_id = formData.org_id;

      var actqty = 0;
      var qtyvendor = 0;
      var ordernotes1 = "";
      var ordernotes2 = "";
      var ordernotes3 = "";
      var vendororigindate = "";
      var scheduledate = "";


      if (status == "") {
        const stat = document.getElementById('status') as HTMLSelectElement;
        status = stat.value;
      }
      if (progress == 0) {
        const prog = document.getElementById('progress') as HTMLSelectElement;
        progress = parseInt(prog.value)
      }
      if (position == 0) {
        const pos = document.getElementById('position') as HTMLSelectElement;
        position = parseInt(pos.value)
      }

      const qty_vendorElement = document.getElementById('qty_vendor') as HTMLInputElement;
      const act_qtyElement = document.getElementById('act_qty') as HTMLInputElement;
      const order_notes1Element = document.getElementById('order_notes1') as HTMLInputElement;
      const order_notes2Element = document.getElementById('order_notes2') as HTMLInputElement;
      const order_notes3Element = document.getElementById('order_notes3') as HTMLInputElement;
      const vendor_origin_dateElement = document.getElementById('vendor_origin_date') as HTMLInputElement;
      const schedule_dateElement = document.getElementById('schedule_date') as HTMLInputElement;

      if (qty_vendorElement) {
        qtyvendor = parseInt(qty_vendorElement.value);
      }
      if (act_qtyElement) {
        actqty = parseInt(act_qtyElement.value);
      }
      if (order_notes1Element) {
        ordernotes1 = order_notes1Element.value;
      }
      if (order_notes2Element) {
        ordernotes2 = order_notes2Element.value;
      }
      if (order_notes3Element) {
        ordernotes3 = order_notes3Element.value;
      }
      if (vendor_origin_dateElement) {
        vendororigindate = vendor_origin_dateElement.value;
      }
      if (schedule_dateElement) {
        scheduledate = schedule_dateElement.value;
      }

      const response = await axios.post(UPDATE_STATUS_API, {
        "order_id": order_id,
        "no_item": no_item,
        "org_id": org_id,
        "qty_vendor": qtyvendor,
        "act_qty": actqty,
        "status1": status,
        "vendor_origin_date": vendororigindate,
        "schedule_date": scheduledate,
        "order_notes1": ordernotes1,
        "order_notes2": ordernotes2,
        "order_notes3": ordernotes3,
        "progress": progress,
        "position": position
      })
      const jsonData = response.data.code;
      if (jsonData == 200) {
        const responseHistory = await axios.post(CREATE_HISTORY_API, {
          "order_id": order_id,
          "notes1": ordernotes1,
          "notes2": ordernotes2,
          "notes3": ordernotes3,
          "status_id": status,
          "progress_id": progress,
          "position_id": position
        })
      }
      setShowModal(false);
      //handleRefresh();
      fetchDataFromAPI();

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  async function fetchDataFromAPI() {
    try {
      const response = await axios.get(`${GET_INV_GROUP}`);
      const jsonData = response.data.data;
      console.log(jsonData);
      setItems(jsonData);

      // Hapus dan tambahkan ulang data baru ke DataTable
      var table = $('#myTable').DataTable();
      table.clear().rows.add(jsonData).draw();

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const fetchData = async () => {
    try {
      const response = await axios.get(`${GET_INV_GROUP}`);
      const jsonData = response.data.data;      
      setItems(jsonData);
      $('#myTable').DataTable({
        info: true,
        paging: true,
        pageLength: 10,
        scrollCollapse: true,
        scrollX: true,
        fixedColumns: {
          right: 2
        },
        data: jsonData,
        columns: [
          { title: 'PO Number', data: 'order_id' },
          { title: 'Order Number', data: 'vendor_po_no' },
          { title: 'No', data: 'seq_no' },
          { title: 'Item', data: 'no_item' },
          { title: 'Description', data: 'item_name' },
          { title: 'PO Number For Customer', data: 'customer_po' },
          { title: 'Customer', data: 'partner_company' },
          { title: 'BD', data: 'person' },
          { title: 'Needs', data: 'promo_code' },
          { title: 'Notes', data: 'order_notes1' },
          { title: 'Sales', data: 'sales_user' },
          { title: 'Qty (Order)', data: 'qty_po' },
          { title: 'Qty Hach', data: 'qty_vendor' },
          { title: 'Qty Real', data: 'act_qty' },
          { title: 'Date Update', data: 'updated_date' },
          { title: 'Est Date Hach', data: 'vendor_origin_date' },
          { title: 'Progress', data: 'prog_name' },
          { title: 'Position', data: 'pos_name' },
          { title: 'Status', data: null },
          { title: 'Action', data: null },
        ],
        columnDefs: [
          {
            targets: 18,
            render: function (data, type, full, meta) {
              if (data.status1 == 1) {

                data =
                  '<button class="btn btn-primary btn-sm btn-flat" type="button">' + data.status_item + '</i></button>';

              } else if (data.status1 == 2) {
                data =
                  '<button class="btn btn-success btn-sm btn-flat"  type="button">' + data.status_item + '</i></button>';
              } else if (data.status1 == 3) {
                data =
                  '<button class="btn btn-danger btn-sm btn-flat"  type="button">' + data.status_item + '</i></button>';
              } else if (data.status1 == 4) {
                data =
                  '<button class="btn btn-warning btn-sm btn-flat" type="button">' + data.status_item + '</i></button>';
              }
              else if (data.status1 == 5) {
                data =
                  '<button class="btn btn-info btn-sm btn-flat" type="button">' + data.status_item + '</i></button>';
              } else {
                data =
                  '<button class="btn btn-secondary btn-sm btn-flat" type="button">No Status</i></button>';
              }
              return data;
            }
          },
          {
            targets: 19,
            render: function (data, type, full, meta) {
              if (type === 'display') {
                data =
                  '<button class="btn btn-icon btn-primary btn-sm" id="' + data.no_idx + '" type="button"><i class="fa fa-cog" aria-hidden="true"></i></button>';
              }
              return data;
            }
          }

        ],
      });

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  useEffect(() => {
    // Handle button click event
    $('#myTable').on('click', 'button', function () {

      const iddata = this.id;
      const rowData = $(this).closest('tr').find('td').map(function () {
        return $(this).text();
      }).get();

      const rowId = $(this).closest('tr').attr('id');
      // Call your custom onClick handler with row data or rowId
      handleClick(rowData, iddata);
    });

    fetchData();
    fetchOrderStatuses();
    fetchOrderPositions();
    fetchOrderProgreses();

  }, []);

  const handleClick = async (rowData: any, rowId: any) => {
    const GET_INV_BYID_DATA = `${GET_INV_BYID}?order_id=` + rowId
    const response = await axios.get(`${GET_INV_BYID_DATA}`);
    const dataOrder = response.data;
    setFormData(dataOrder.data);
    setShowModal(true);
  };

  return (
    <div>
      <div className={`card ${className}`}>
        <div className='card-body py-3'>
          <div>
            <table id="myTable" className="table table-hover table-rounded table-striped border gy-4 gs-7">
              <thead>
                <tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
                  <th className="min-w-100px">PO Number</th>
                  <th className="min-w-100px">Order Number</th>
                  <th>No</th>
                  <th>Item</th>
                  <th className="min-w-400px">Description</th>
                  <th className="min-w-200px">PO Number For Customer</th>
                  <th>Customer</th>
                  <th>BD</th>
                  <th>Needs</th>
                  <th>Notes</th>
                  <th>Sales</th>
                  <th className="min-w-100px">Qty (Order)</th>
                  <th className="min-w-100px">Qty Hach</th>
                  <th className="min-w-100px">Qty Real</th>
                  <th className="min-w-100px">Date Update</th>
                  <th className="min-w-100px">Est Date Hach</th>
                  <th>Progress</th>
                  <th>Position</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
        <Modal show={showModal} onHide={handleCloseModal} className='modal-lg'>
          <Modal.Header closeButton>
            <Modal.Title>Update Status</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-lg-6">
                <div className="mb-3">
                  <label className="form-label">PO Number</label>
                  <input
                    type="text"
                    className="form-control form-control-white form-control-sm"
                    placeholder="order_id"
                    name='order_id'
                    id='order_id'
                    ref={inputRef}
                    value={formData.order_id} disabled
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Order Number</label>
                  <input
                    type="text"
                    className="form-control form-control-white form-control-sm"
                    placeholder="vendor_po_no"
                    name='vendor_po_no'
                    id='vendor_po_no'
                    value={formData.vendor_po_no} disabled
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">No</label>
                  <input
                    type="text"
                    className="form-control form-control-white form-control-sm"
                    placeholder="seq_no"
                    name='seq_no'
                    id='seq_no'
                    value={formData.seq_no} disabled
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Catalogue Number</label>
                  <input
                    type="text"
                    className="form-control form-control-white form-control-sm"
                    placeholder="no_item"
                    name='no_item'
                    id='no_item'
                    value={formData.no_item} disabled
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Description</label>
                  <input
                    type="text"
                    className="form-control form-control-white form-control-sm"
                    placeholder="item_name"
                    name='item_name'
                    id='item_name'
                    value={formData.item_name} disabled
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Customer PO Number</label>
                  <input
                    type="text"
                    className="form-control form-control-white form-control-sm"
                    placeholder="customer_po"
                    name='customer_po'
                    id='customer_po'
                    value={formData.customer_po} disabled
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Customer Name</label>
                  <input
                    type="text"
                    className="form-control form-control-white form-control-sm"
                    placeholder="partner_company"
                    name='partner_company'
                    id='partner_company'
                    value={formData.partner_company} disabled
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">BD</label>
                  <input
                    type="text"
                    className="form-control form-control-white form-control-sm"
                    placeholder="person"
                    name='person'
                    id='person'
                    value={formData.person} disabled
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Needs</label>
                  <input
                    type="text"
                    className="form-control form-control-white form-control-sm"
                    placeholder="promo_code"
                    name='promo_code'
                    id='promo_code'
                    value={formData.promo_code} disabled
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Sales</label>
                  <input
                    type="text"
                    className="form-control form-control-white form-control-sm"
                    placeholder="sales_user"
                    name='sales_user'
                    id='sales_user'
                    value={formData.sales_user} disabled
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Date Update</label>
                  <input
                    type="text"
                    className="form-control form-control-white form-control-sm"
                    placeholder="updated_at"
                    name='updated_at'
                    id='updated_at'
                    value={formData.updated_date} disabled
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Qty Order</label>
                  <input
                    type="text"
                    className="form-control form-control-white form-control-sm"
                    placeholder="Qty Order"
                    name='qty_order'
                    id='qty_order'
                    value={formData.qty_po} disabled
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mb-3">
                  <label className="form-label">Qty Hach</label>
                  <input
                    type="number"
                    className="form-control form-control-white form-control-sm"
                    placeholder="Qty Hach"
                    name='qty_vendor'
                    id='qty_vendor'
                    defaultValue={formData.qty_vendor}
                    onChange={handleChangeqty_vendor}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Qty Real</label>
                  <input
                    type="number"
                    className="form-control form-control-white form-control-sm"
                    placeholder="Qty Real"
                    name='act_qty'
                    id='act_qty'
                    defaultValue={formData.act_qty}
                    onChange={handleChangeact_qty}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Schedule Date</label>
                  <input
                    type="date"
                    className="form-control form-control-white form-control-sm"
                    placeholder="schedule_date"
                    name='schedule_date'
                    id='schedule_date'
                    defaultValue={formData.schedule_date}
                    onChange={handleChangeschedule_date}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Origin Date Hach</label>
                  <input
                    type="date"
                    className="form-control form-control-white form-control-sm"
                    placeholder="origin_est_date_hach"
                    name='vendor_origin_date'
                    id='vendor_origin_date'
                    defaultValue={formData.vendor_origin_date}
                    onChange={handleChangevendor_origin_date}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Progress</label>
                  <select id='progress'
                    className="form-select form-select-white form-select-sm"
                    aria-label="Progress"
                    value={selectedOrderProgress}
                    onChange={handleOrderProgressChange}
                  >
                    <option value={formData.progress}>{formData.prog_name}</option>
                    {orderprogreses.length > 0 &&
                      orderprogreses.map((items) => (
                        <option key={items.id} value={items.id}>
                          {items.prog_name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="mb-3">
                  <label className="form-label">Position</label>
                  <select id='position'
                    className="form-select form-select-white form-select-sm"
                    aria-label="Position"
                    value={selectedOrderPosition}
                    onChange={handleOrderPositionChange}
                  >
                    <option value={formData.position}>{formData.pos_name}</option>
                    {orderpositions.length > 0 &&
                      orderpositions.map((items) => (
                        <option key={items.id} value={items.id}>
                          {items.pos_name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="mb-3">
                  <label className="form-label">Status</label>
                  <select id='status'
                    className="form-select form-select-white form-select-sm"
                    aria-label="Status"
                    value={selectedOrderStatus}
                    onChange={handleOrderStatusChange}
                  >
                    <option value={formData.status1} >{formData.status_item}</option>
                    {orderstatuses.length > 0 &&
                      orderstatuses.map((items) => (
                        <option key={items.status_id} value={items.status_id}>
                          {items.status_name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="mb-3">
                  <label className="form-label">Notes</label>
                  <input
                    type="text"
                    className="form-control form-control-white form-control-sm"
                    placeholder="Notes"
                    name='order_notes1'
                    id='order_notes1'
                    defaultValue={formData.order_notes1}
                    onChange={handleChangesorder_notes1}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Notes Tambahan</label>
                  <input
                    type="text"
                    className="form-control form-control-white form-control-sm"
                    placeholder="Notes"
                    name='order_notes2'
                    id='order_notes2'
                    defaultValue={formData.order_notes2}
                    onChange={handleChangesorder_notes2}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Another Notes</label>
                  <input
                    type="text"
                    className="form-control form-control-white form-control-sm"
                    placeholder="Notes"
                    name='order_notes3'
                    id='order_notes3'
                    defaultValue={formData.order_notes3}
                    onChange={handleChangesorder_notes3}
                    readOnly={false}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Day Gap</label>
                  <input
                    type="text"
                    className="form-control form-control-white form-control-sm"
                    placeholder="Day Gap"
                    name='daygap'
                    id='daygap'
                    value={daygap}
                    disabled
                  />
                </div>

              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" size="sm" onClick={handleCloseModal}>
              Close
            </Button>
            <button type='button' id='view' className='btn btn-primary btn-flat btn-sm' onClick={UpdateStatus}>Update</button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  )
}

export { UpdateStatusPage }
