import React, {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import { GoodReceivedPage } from './GoodReceivedPage'

const GoodReceivedPageWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Good Received</PageTitle>
      <GoodReceivedPage className='mb-5 mb-xl-8' />
    </>
  )
}

export default GoodReceivedPageWrapper
