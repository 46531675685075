import React, { useState, useEffect } from 'react';
import axios from 'axios';
import $ from 'jquery';
import 'datatables.net-dt';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import 'datatables.net-fixedcolumns'
import { Button, Modal } from 'react-bootstrap';
import { number } from 'yup';

type Props = {
  className: string
}

const PurchaseOrderPage: React.FC<Props> = ({ className }) => {


  interface Item {
    no_group: string;
    group_name: string;
    status: number;
    no_account: number;
    group_account: number;
    group_type: string;
    type_sales: number;
    wip_account: number;
    default_fg: boolean;
    default_rm: boolean;
    default_wip: boolean;
    jenis: string;
  }


  const API_URL = process.env.REACT_APP_THEME_API_URL
  const GET_PO_DATA = `${API_URL}/PurchaseOrder`
  const GET_PO_BYID = `${API_URL}/PurchaseOrder/getbyid`
  const [items, setItems] = useState<Array<Item>>([]);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    order_id: '',
    date_order: '',
    partner_id: '',
    partner_name: '',
    require_date: '',
    term_payment: '',
    vendor_po_no: '',
    bd: '',
    status: '',
    status_desc: '',
    type_trans: '',
    type_trans_order: '',
    approved_by: '',
    periode: '',
    seq_no: '',
    customer_po: '',
    no_item: '',
    item_name: '',
    uom: '',
    qty_po: 0,
    order_price: 0,
    tax_procentage: number,
    promo_code: '',
    curr_id: '',
    rate: '',
    subtotal: '',
    so_number: '',
    sales_user: '',
    taxes: '',
    type_loco: '',
    address: '',
    city: '',
    no_account: '',
    discount: '',
    wht:'',
    total:0,
    price:0,
    vat:'',
    payment_method:'',
    quantity:'',
    schedule:'',
    delivery_time:'',
    ship_to:'',
    type_item:'',
    notes:''
  });

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleShowModal = (name: any) => {
    console.log(`Hello, ${name}!`);
    setShowModal(true);
  };


  useEffect(() => {
    // Handle button click event
    $('#myTable').on('click', 'button', function () {

      const iddata = this.id;
      const rowData = $(this).closest('tr').find('td').map(function () {
        return $(this).text();
      }).get();

      const rowId = $(this).closest('tr').attr('id');
      // Call your custom onClick handler with row data or rowId
      handleClick(rowData, iddata);
    });

    const fetchData = async () => {
      try {
        const response = await axios.get(`${GET_PO_DATA}`);
        const jsonData = response.data.data;
        setItems(jsonData);
        $('#myTable').DataTable({
          info: true,
          paging: true,
          pageLength: 25,
          scrollCollapse: true,
          fixedColumns: {
            left: 1
          },
          data: jsonData,
          columns: [
            { title: 'PO Number', data: 'order_id' },
            { title: 'Date', data: 'date_order' },
            { title: 'Supplier', data: 'partner_name' },
            { title: 'Item No', data: 'no_item' },
            { title: 'Item Name', data: 'item_name' },
            { title: 'Date Required', data: 'require_date' },
            { title: 'Term Of Payment', data: 'term_payment' },
            { title: 'Status', data: 'status_desc' },
            { title: 'Action', data: null },
          ],
          columnDefs: [
            {
              targets: 8,
              render: function (data, type, full, meta) {
                if (type === 'display') {
                  data =
                    '<button class="btn btn-icon btn-primary btn-sm" id="' + data.order_id + '" type="button"><i class="fa fa-search" aria-hidden="true"></i></button>';
                }
                return data;
              }
            }
          ]
        });

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  const handleClick = async (rowData: any, rowId: any) => {
    console.log('Button clicked:', rowData, rowId);
    const GET_INV_BYID_DATA = `${GET_PO_BYID}?order_id=` + rowId
    const response = await axios.get(`${GET_INV_BYID_DATA}`);
    const dataSupplier = response.data;
    setFormData(dataSupplier.data);
    setShowModal(true);
  };

  return (
    <div>

      <div className={`card ${className}`}>
        {/* begin::Header */}
        {/* <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Master Supplier</span>
        </h3>
        <div className='card-toolbar'>
        </div>
      </div> */}
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          <div>
          <table id="myTable" className="table table-hover table-rounded table-striped border gy-4 gs-7">
            <thead>
            <tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
                  <th>PO Number</th>
                  <th>Date</th>
                  <th>Supplier</th>
                  <th>Item No</th>
                  <th>Item Name</th>
                  <th>Date Required</th>
                  <th>Term Of Payment</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
            </table>
          </div>

        </div>
        {/* begin::Body */}

      </div>
      <Modal show={showModal} onHide={handleCloseModal} className='modal-lg'>
        <Modal.Header closeButton>
          <Modal.Title>Purchase Order Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-6">
              <div className="mb-3">
                <label className="form-label">PO Number</label>
                <input
                  type="text"
                  className="form-control form-control-white"
                  placeholder="PO Number"
                  name='order_id'
                  id='order_id'
                  value={formData.order_id} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Date</label>
                <input
                  type="text"
                  className="form-control form-control-white"
                  placeholder="Date"
                  name='date_order'
                  id='date_order'
                  value={formData.date_order} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">PO Type</label>
                <input
                  type="text"
                  className="form-control form-control-white"
                  placeholder="PO Type"
                  name='type_trans'
                  id='type_trans'
                  value={formData.type_trans} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Supplier</label>
                <input
                  type="text"
                  className="form-control form-control-white"
                  placeholder="Supplier"
                  name='partner_name'
                  id='partner_name'
                  value={formData.partner_name} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Description</label>
                <input
                  type="text"
                  className="form-control form-control-white"
                  placeholder="Description"
                  name='notes'
                  id='notes'
                  value={formData.notes} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Ship To</label>
                <input
                  type="text"
                  className="form-control form-control-white"
                  placeholder="Ship To"
                  name='ship_to'
                  id='ship_to'
                  value={formData.ship_to} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Type Item</label>
                <input
                  type="text"
                  className="form-control form-control-white"
                  placeholder="Type Item"
                  name='type_item'
                  id='type_item'
                  value={formData.type_item} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Item Name</label>
                <input
                  type="text"
                  className="form-control form-control-white"
                  placeholder="Item Name"
                  name='item_name'
                  id='item_name'
                  value={formData.item_name} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Item No</label>
                <input
                  type="text"
                  className="form-control form-control-white"
                  placeholder="Item No"
                  name='item_no'
                  id='item_no'
                  value={formData.no_item} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Currency</label>
                <input
                  type="text"
                  className="form-control form-control-white"
                  placeholder="Currency"
                  name='curr_id'
                  id='curr_id'
                  value={formData.curr_id} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Schedule</label>
                <input
                  type="text"
                  className="form-control form-control-white"
                  placeholder="Schedule"
                  name='schedule'
                  id='schedule'
                  value={formData.schedule} disabled
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="mb-3">
                <label className="form-label">Date Required</label>
                <input
                  type="text"
                  className="form-control form-control-white"
                  placeholder="Date Required"
                  name='require_date'
                  id='require_date'
                  value={formData.require_date} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Delivery Time</label>
                <input
                  type="text"
                  className="form-control form-control-white"
                  placeholder="Delivery Time"
                  name='delivery_time'
                  id='delivery_time'
                  value={formData.delivery_time} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Payment Method</label>
                <input
                  type="text"
                  className="form-control form-control-white"
                  placeholder="Payment Method"
                  name='payment_method'
                  id='payment_method'
                  value={formData.payment_method} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Term Payment</label>
                <input
                  type="text"
                  className="form-control form-control-white"
                  placeholder="Term Payment"
                  name='term_payment'
                  id='term_payment'
                  value={formData.term_payment} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Status</label>
                <input
                  type="text"
                  className="form-control form-control-white"
                  placeholder="Status"
                  name='status'
                  id='status'
                  value={formData.status_desc} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Quantity</label>
                <input
                  type="text"
                  className="form-control form-control-white"
                  placeholder="Quantity"
                  name='quantity'
                  id='quantity'
                  value={Number(formData.qty_po).toLocaleString('en')} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Price</label>
                <input
                  type="text"
                  className="form-control form-control-white"
                  placeholder="Price"
                  name='price'
                  id='price'
                  value={Number(formData.order_price).toLocaleString('en')} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Disc (%)</label>
                <input
                  type="text"
                  className="form-control form-control-white"
                  placeholder="Disc"
                  name='discount'
                  id='discount'
                  value={formData.discount} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">VAT (%)</label>
                <input
                  type="text"
                  className="form-control form-control-white"
                  placeholder="VAT"
                  name='vat'
                  id='vat'
                  value={Number(formData.tax_procentage).toLocaleString('en')} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">WHT (%)</label>
                <input
                  type="text"
                  className="form-control form-control-white"
                  placeholder="WHT"
                  name='wht'
                  id='wht'
                  value={formData.wht} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Total</label>
                <input
                  type="text"
                  className="form-control form-control-white"
                  placeholder="Total"
                  name='total'
                  id='total'
                  value={Number(formData.subtotal).toLocaleString('en')} disabled
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseModal}>
            Close
          </Button>
          {/* <Button variant="primary" onClick={handleCloseModal}>
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>
    </div>


  )
}

export { PurchaseOrderPage }
