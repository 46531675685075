import React, {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import { UserRolePage } from './UserRolePage'

const UserRolePageWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>User Role</PageTitle>      
      <UserRolePage className='mb-5 mb-xl-8' />
    </>
  )
}

export default UserRolePageWrapper
