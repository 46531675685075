import React, {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {SupplierPage} from './SupplierPage'

const SupplierPageWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Master Supplier</PageTitle>      
      <SupplierPage className='mb-5 mb-xl-8' />
    </>
  )
}

export default SupplierPageWrapper
