import React, { useState, useEffect } from 'react';
import axios from 'axios';
import $ from 'jquery';
import 'datatables.net-dt';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import 'datatables.net-fixedcolumns'
import 'popper.js';
import { Button, Modal } from 'react-bootstrap';

type Props = {
  className: string
}

const SupplierPage: React.FC<Props> = ({ className }) => {

  const API_URL = process.env.REACT_APP_THEME_API_URL
  const GET_INV_GROUP = `${API_URL}/partner/getbytype?partner_type=SUPPLIER`
  const GET_SUPPLIER_BYID = `${API_URL}/partner/getbypartnerid`
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    partner_id: '',
    partner_name: '',
    contact_name: '',
    contact_title: '',
    address: '',
    city: '',
    postal_code: '',
    country: '',
    phone: '',
    mobile: '',
    fax: '',
    email: '',
    url: '',
    npwp: '',
    wht: '',
    tax_rate: '',
    vat: '',
    partner_type: '',
    no_account: '',
    term_code: '',
    def: '',
    category_id: '',
    discount_procentage: '',
    discount_value: '',
    partner_map_id: '',
    prev_income: '',
    prev_income_period_from: '',
    prev_income_period_to: '',
    income_period_from: '',
    income_period_to: '',
    prev_pph: '',
    partner_nik: '',
    model_pay: '',
    birth_date: '',
    status: '',
    partner_key: '',
    bank_receipt_id: '',
    model_discount: '',
    fine_procentage: '',
    no_bank_virtual: '',
  });

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleShowModal = (name: any) => {
    console.log(`Hello, ${name}!`);
    setShowModal(true);
  };



  useEffect(() => {

    // Handle button click event
    $('#myTable').on('click', 'button', function () {

      const iddata = this.id;
      const rowData = $(this).closest('tr').find('td').map(function () {
        return $(this).text();
      }).get();

      const rowId = $(this).closest('tr').attr('id');
      // Call your custom onClick handler with row data or rowId
      handleClick(rowData, iddata);
    });

    const fetchData = async () => {
      try {
        const response = await axios.get(`${GET_INV_GROUP}`);
        const jsonData = response.data.data;
        //setItems(jsonData);        
        $('#myTable').DataTable({
          info: true,
          paging: true,
          pageLength: 10,
          scrollCollapse: true,
          fixedColumns: {
            left: 1
          },
          data: jsonData,
          columns: [
            { title: 'ID', data: 'partner_id' },
            { title: 'Name', data: 'partner_name' },
            { title: 'Contact Name', data: 'contact_name' },
            { title: 'Phone', data: 'phone' },
            { title: 'City', data: 'city' },
            { title: 'Country', data: 'country' },
            { title: 'Action', data: null },
          ],
          columnDefs: [
            {
              targets: 6,
              render: function (data, type, full, meta) {
                if (type === 'display') {
                  data =
                    '<button class="btn btn-icon btn-primary btn-sm" id="' + data.partner_id + '" type="button"><i class="fa fa-search" aria-hidden="true"></i></button>';
                }
                return data;
              }
            },
            {
              target: 0,
              visible: false,
              searchable: false,
            },
          ]
        });

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  const handleClick = async (rowData: any, rowId: any) => {
    console.log('Button clicked:', rowData, rowId);
    // Perform custom logic here based on the clicked row data or rowId    

    const GET_SUPPLIER_BYID_DATA = `${GET_SUPPLIER_BYID}?partner_id=` + rowId + `&partner_type=SUPPLIER`
    const response = await axios.get(`${GET_SUPPLIER_BYID_DATA}`);
    const dataSupplier = response.data.data;
    console.log(dataSupplier[0]);
    setFormData(dataSupplier[0]);
    console.log(formData)
    setShowModal(true);
  };


  return (
    <div>
      <div className={`card ${className}`}>
        {/* begin::Header */}
        {/* <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Master Supplier</span>
        </h3>
        <div className='card-toolbar'>
        </div>
      </div> */}
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          <div>
            <table id="myTable" className="table table-hover table-rounded table-striped border gy-4 gs-7">
              <thead>
                <tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
                  <th className="min-w-200px">Partner Id</th>
                  <th className="min-w-200px">Name</th>
                  <th className="min-w-200px">Contact Name</th>
                  <th className="min-w-200px">Phone</th>
                  <th className="min-w-200px">City</th>
                  <th className="min-w-200px">Country</th>
                  <th className="min-w-100px">Action</th>
                </tr>
              </thead>
            </table>
          </div>

        </div>
        {/* begin::Body */}


      </div>

      <Modal show={showModal} onHide={handleCloseModal} className='modal-lg'>
        <Modal.Header closeButton>
          <Modal.Title>Supplier Detail Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-6">
              <div className="mb-3">
                <label className="form-label">Code</label>
                <input
                  type="text"
                  className="form-control form-control-white form-control-sm"
                  placeholder="Code"
                  name='partner_id'
                  id='partner_id'
                  value={formData.partner_id}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Name</label>
                <input
                  type="text"
                  className="form-control form-control-white form-control-sm"
                  placeholder="name@example.com"
                  id='partner_name'
                  value={formData.partner_name}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Contact Person</label>
                <input
                  type="text"
                  className="form-control form-control-white form-control-sm"
                  placeholder="name@example.com"
                  id='contact_name'
                  value={formData.contact_name}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Occupation</label>
                <input
                  type="text"
                  className="form-control form-control-white form-control-sm"
                  placeholder="name@example.com"
                  id='address'
                  value={formData.address}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Address</label>
                <input
                  type="text"
                  className="form-control form-control-white form-control-sm"
                  placeholder="name@example.com"
                  id='address'
                  value={formData.address}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Country</label>
                <input
                  type="text"
                  className="form-control form-control-white form-control-sm"
                  placeholder="name@example.com"
                  id='country'
                  value={formData.country}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">City</label>
                <input
                  type="text"
                  className="form-control form-control-white form-control-sm"
                  placeholder="name@example.com"
                  id='city'
                  value={formData.city}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Zip Code</label>
                <input
                  type="text"
                  className="form-control form-control-white form-control-sm"
                  placeholder="name@example.com"
                  id='postal_code'
                  value={formData.postal_code}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Phone</label>
                <input
                  type="text"
                  className="form-control form-control-white form-control-sm"
                  placeholder="name@example.com"
                  id='phone'
                  value={formData.phone}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="mb-3">
                <label className="form-label">Cell Phone</label>
                <input
                  type="text"
                  className="form-control form-control-white form-control-sm"
                  placeholder="name@example.com"
                  id='mobile'
                  value={formData.mobile}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Fax</label>
                <input
                  type="text"
                  className="form-control form-control-white form-control-sm"
                  placeholder="name@example.com"
                  id='fax'
                  value={formData.fax}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Email</label>
                <input
                  type="text"
                  className="form-control form-control-white form-control-sm"
                  placeholder="name@example.com"
                  id='email'
                  value={formData.email}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Website</label>
                <input
                  type="text"
                  className="form-control form-control-white form-control-sm"
                  placeholder="name@example.com"
                  id='url'
                  value={formData.url}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Term Payment</label>
                <input
                  type="text"
                  className="form-control form-control-white form-control-sm"
                  placeholder="name@example.com"
                  id='term_code'
                  value={formData.term_code}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Bank Receipt</label>
                <input
                  type="text"
                  className="form-control form-control-white form-control-sm"
                  placeholder="name@example.com"
                  id='bank_receipt_id'
                  value={formData.bank_receipt_id}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">No Bank Virtual</label>
                <input
                  type="text"
                  className="form-control form-control-white form-control-sm"
                  placeholder="name@example.com"
                  id='no_bank_virtual'
                  value={formData.no_bank_virtual}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Category</label>
                <input
                  type="text"
                  className="form-control form-control-white form-control-sm"
                  placeholder="name@example.com"
                  id='category_id'
                  value={formData.category_id}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Status</label>
                <input
                  type="text"
                  className="form-control form-control-white form-control-sm"
                  placeholder="name@example.com"
                  id='status'
                  value={formData.status}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="mb-12">
              <span>
                Tax Info
              </span>
            </div>
            <div className="col-lg-6">
              <label className="form-label">NPWP</label>
              <input
                type="text"
                className="form-control form-control-white form-control-sm"
                placeholder="name@example.com"
                id='npwp'
                value={formData.npwp}
              />
            </div>
            <div className="col-lg-1">
              <label className="form-label">VAT</label>
              <input
                type="text"
                className="form-control form-control-white form-control-sm"
                placeholder="name@example.com"
                id='vat'
                value={formData.vat}
              />
            </div>
            <div className="col-lg-1">
              <label className="form-label">WHT</label>
              <input
                type="text"
                className="form-control form-control-white form-control-sm"
                placeholder="name@example.com"
                id='wht'
                value={formData.wht}
              />
            </div>
            <div className="col-lg-1">
              <label className="form-label">Fine</label>
              <input
                type="text"
                className="form-control form-control-white form-control-sm"
                placeholder="name@example.com"
                id='mobile'
                value={formData.mobile}
              />
            </div>
            <div className="col-lg-1">
              <label className="form-label">Disc</label>
              <input
                type="text"
                className="form-control form-control-white form-control-sm"
                placeholder="name@example.com"
                id='discount_procentage'
                value={formData.discount_procentage}
              />
            </div>
            <div className="col-lg-2">
              <label className="form-label">Disc Value</label>
              <input
                type="text"
                className="form-control form-control-white form-control-sm"
                placeholder="name@example.com"
                id='discount_value'
                value={formData.discount_value}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          {/* <Button variant="primary" onClick={handleCloseModal}>
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export { SupplierPage }
