import React, {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import { ProfilePage } from './ProfilePage'

const ProfilePageWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Profile User</PageTitle>      
      <ProfilePage className='mb-5 mb-xl-8' />
    </>
  )
}

export default ProfilePageWrapper
