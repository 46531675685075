import { useIntl } from 'react-intl'
import { MenuItem } from './MenuItem'
import { MenuInnerWithSub } from './MenuInnerWithSub'
import { MegaMenu } from './MegaMenu'
import { useAuth } from '../../../../../app/modules/auth'

export function MenuInner() {
  const intl = useIntl()
  const { currentUser, logout } = useAuth()
  return (
    <>
      {/* <MenuItem title={intl.formatMessage({ id: 'MENU.DASHBOARD' })} to='/dashboard' /> */}
      <div className='fw-bolder d-flex align-items-center fs-5'>
        Selamat Datang {currentUser?.username} {currentUser?.rolename} - {currentUser?.organization_name}
        {/* <span className='badge badge-light-primary fw-bolder fs-4 px-2 py-1 ms-2'>{currentUser?.username}</span> */}
        {/* <span className='badge badge-light-warning fw-bolder fs-6 px-2 py-1 ms-2'>{currentUser?.rolename} - {currentUser?.organization_name}</span>         */}
      </div>
      {/* <MenuItem title='Layout Builder' to='/builder' />      

      <MenuInnerWithSub
        isMega={true}
        title='Option Menu'
        to='/mega-menu'
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
        <MegaMenu />
      </MenuInnerWithSub> */}
    </>
  )
}
