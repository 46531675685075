import React, { useState, useEffect } from 'react';
import axios from 'axios';
import $ from 'jquery';
import 'datatables.net-dt';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import 'datatables.net-fixedcolumns'
import { Button, Modal } from 'react-bootstrap';

type Props = {
  className: string
}

const GoodReceivedPage: React.FC<Props> = ({ className }) => {


  interface Item {
    no_group: string;
    group_name: string;
    status: number;
    no_account: number;
    group_account: number;
    group_type: string;
    type_sales: number;
    wip_account: number;
    default_fg: boolean;
    default_rm: boolean;
    default_wip: boolean;
    jenis: string;
  }


  const API_URL = process.env.REACT_APP_THEME_API_URL
  const GET_INV_GROUP = `${API_URL}/GoodReceived`
  const GET_INV_BYID = `${API_URL}/GoodReceived/getbyid`
  const [items, setItems] = useState<Array<Item>>([]);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    grn: '',
    grndate: '',
    supplier: '',
    po_supplier: '',
    po_supplie2: '',
    po_customer: '',
    item: '',
    itemname: '',
    qty: '',
    uom: '',
    statusitem: '',
    batch: '',
    expired_date: '',
    mnfg_date: '',
    so: '',
    username: '',
    status: '',
    ref_notes: '',
    type_trans: '',
    inv_supplier: '',
    supplier1: '',
    org_id: '',
    seqno: ''
  });

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleShowModal = (name: any) => {
    console.log(`Hello, ${name}!`);
    setShowModal(true);
  };

  const formatNumber = (number: any) => {
    return number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  };


  useEffect(() => {
    // Handle button click event
    $('#myTable').on('click', 'button', function () {

      const iddata = this.id;
      const rowData = $(this).closest('tr').find('td').map(function () {
        return $(this).text();
      }).get();

      const rowId = $(this).closest('tr').attr('id');
      // Call your custom onClick handler with row data or rowId
      handleClick(rowData, iddata);
    });

    const fetchData = async () => {
      try {
        const response = await axios.get(`${GET_INV_GROUP}`);
        const jsonData = response.data.data;
        setItems(jsonData);
        $('#myTable').DataTable({
          info: true,
          paging: true,
          scrollX: true,
          scrollCollapse: true,
          fixedColumns: {
            right: 1
          },
          data: jsonData,
          columns: [
            { title: 'GRN', data: 'grn' },
            { title: 'GRN Date', data: 'grndate' },
            { title: 'Supplier', data: 'supplier' },
            { title: 'PO Supplier', data: 'po_supplier' },
            { title: 'PO Customer', data: 'po_customer' },
            { title: 'Item', data: 'item' },
            { title: 'Item Name', data: 'itemname' },
            { title: 'Qty', data: 'qty' },
            { title: 'UOM', data: 'uom' },
            { title: 'Status Item', data: 'statusitem' },
            { title: 'Batch', data: 'batch' },
            { title: 'Exp Date', data: 'expired_date' },
            { title: 'MNFG Date', data: 'mnfg_date' },
            { title: 'SO', data: 'so' },
            { title: 'Username', data: 'username' },
            { title: 'Seq No', data: 'seqno' },
            { title: 'Inv Supplier', data: 'inv_supplier' },
            { title: 'Action', data: null },
          ],
          columnDefs: [
            {
              targets: 17,
              render: function (data, type, full, meta) {
                console.log(data);
                if (type === 'display') {
                  data =
                    '<button class="btn btn-icon btn-primary btn-sm" id="' + data.grn + '" type="button"><i class="fa fa-search" aria-hidden="true"></i></button>';
                }
                return data;
              }
            }
          ]
        });

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  const handleClick = async (rowData: any, rowId: any) => {
    console.log('Button clicked:', rowData, rowId);
    // Perform custom logic here based on the clicked row data or rowId    
    console.log('Button rowData:', rowData[14]);
    console.log('Button rowId:', rowId);
    const GET_INV_BYID_DATA = `${GET_INV_BYID}?trans_id=` + rowId
    const response = await axios.get(`${GET_INV_BYID_DATA}`);
    const dataSupplier = response.data;
    setFormData(dataSupplier.data);
    setShowModal(true);
  };

  return (
    <div>

      <div className={`card ${className}`}>
        <div className='card-body py-3'>
          <div className="table-responsive">
            <table id="myTable" className="table table-striped gy-7 gs-7">
              <thead>
                <tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
                  <th className="min-w-200px">GRN</th>
                  <th className="min-w-200px">GRN DATE</th>
                  <th className="min-w-200px">SUPPLIER</th>
                  <th className="min-w-190px">PO SUPPLIER</th>
                  <th className="min-w-190px">PO CUSTOMER</th>
                  <th className="min-w-100px">ITEM</th>
                  <th className="min-w-500px">ITEM NAME</th>
                  <th>QTY</th>
                  <th>UOM</th>
                  <th>STATUS ITEM</th>
                  <th>BATCH</th>
                  <th className="min-w-200px">EXPDATE</th>
                  <th>MNFGDATE</th>
                  <th>SO</th>
                  <th>USERNAME</th>
                  <th>SEQNO</th>
                  <th>INV SUPPLIER</th>
                  <th>ACTION</th>
                </tr>
              </thead>
            </table>
          </div>

        </div>
        {/* begin::Body */}

      </div>
      <Modal show={showModal} onHide={handleCloseModal} className='modal-lg'>
        <Modal.Header closeButton>
          <Modal.Title>Good Received Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-6">
              <div className="mb-3">
                <label className="form-label">GRN</label>
                <input
                  type="text"
                  className="form-control form-control-white"
                  placeholder="grn"
                  name='grn'
                  id='grn'
                  value={formData.grn} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">GRN Date</label>
                <input
                  type="text"
                  className="form-control form-control-white"
                  placeholder="grndate"
                  name='grndate'
                  id='grndate'
                  value={formData.grndate} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Supplier</label>
                <input
                  type="text"
                  className="form-control form-control-white"
                  placeholder="supplier"
                  name='supplier'
                  id='supplier'
                  value={formData.supplier} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">PO Supplier</label>
                <input
                  type="text"
                  className="form-control form-control-white"
                  placeholder="po_supplier"
                  name='po_supplier'
                  id='po_supplier'
                  value={formData.po_supplier} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">PO Supplier 2</label>
                <input
                  type="text"
                  className="form-control form-control-white"
                  placeholder="po_supplie2"
                  name='po_supplie2'
                  id='po_supplie2'
                  value={formData.po_supplie2} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">PO Customer</label>
                <input
                  type="text"
                  className="form-control form-control-white"
                  placeholder="po_customer"
                  name='po_customer'
                  id='po_customer'
                  value={formData.po_customer} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Item</label>
                <input
                  type="text"
                  className="form-control form-control-white"
                  placeholder="item"
                  name='item'
                  id='item'
                  value={formData.item} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Item Name</label>
                <input
                  type="text"
                  className="form-control form-control-white"
                  placeholder="itemname"
                  name='itemname'
                  id='itemname'
                  value={formData.itemname} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Qty</label>
                <input
                  type="text"
                  className="form-control form-control-white"
                  placeholder="qty"
                  name='qty'
                  id='qty'
                  value={formatNumber(formData.qty)} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">UOM</label>
                <input
                  type="text"
                  className="form-control form-control-white"
                  placeholder="uom"
                  name='uom'
                  id='uom'
                  value={formData.uom} disabled
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="mb-3">
                <label className="form-label">Status Item</label>
                <input
                  type="text"
                  className="form-control form-control-white"
                  placeholder="statusitem"
                  name='statusitem'
                  id='statusitem'
                  value={formData.statusitem} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Batch</label>
                <input
                  type="text"
                  className="form-control form-control-white"
                  placeholder="batch"
                  name='batch'
                  id='batch'
                  value={formData.batch} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Exp Date</label>
                <input
                  type="text"
                  className="form-control form-control-white"
                  placeholder="expired_date"
                  name='expired_date'
                  id='expired_date'
                  value={formData.expired_date} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">MNFG Date</label>
                <input
                  type="text"
                  className="form-control form-control-white"
                  placeholder="mnfg_date"
                  name='mnfg_date'
                  id='mnfg_date'
                  value={formData.mnfg_date} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">SO</label>
                <input
                  type="text"
                  className="form-control form-control-white"
                  placeholder="so"
                  name='so'
                  id='so'
                  value={formData.so} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Username</label>
                <input
                  type="text"
                  className="form-control form-control-white"
                  placeholder="username"
                  name='username'
                  id='username'
                  value={formData.username} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Ref Notes</label>
                <input
                  type="text"
                  className="form-control form-control-white"
                  placeholder="ref_notes"
                  name='ref_notes'
                  id='ref_notes'
                  value={formData.ref_notes} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Type Trans</label>
                <input
                  type="text"
                  className="form-control form-control-white"
                  placeholder="type_trans"
                  name='type_trans'
                  id='type_trans'
                  value={formData.type_trans} disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">INV Supplier</label>
                <input
                  type="text"
                  className="form-control form-control-white"
                  placeholder="inv_supplier"
                  name='inv_supplier'
                  id='inv_supplier'
                  value={formData.inv_supplier} disabled
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          {/* <Button variant="primary" onClick={handleCloseModal}>
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>
    </div>


  )
}

export { GoodReceivedPage }
