import React, { useState, useEffect } from 'react';
import axios from 'axios';
import $ from 'jquery';
import 'datatables.net-dt';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import 'datatables.net-fixedcolumns'
import { Button, Modal } from 'react-bootstrap';
import { stat } from 'fs';
import { table } from 'console';
import { Logout, AuthPage, useAuth } from '../../modules/auth'

type Props = {
  className: string
}


const MonitoringPage: React.FC<Props> = ({ className }) => {
  const { auth, logout, setCurrentUser } = useAuth()


  interface Item {
    no_group: string;
    group_name: string;
    status: number;
    no_account: number;
    group_account: number;
    group_type: string;
    type_sales: number;
    wip_account: number;
    default_fg: boolean;
    default_rm: boolean;
    default_wip: boolean;
    jenis: string;
  }

  interface Company {
    org_id: string;
    org_entity: string;
  }

  interface ItemData {
    no_item: string;
    item_name: string;
  }

  interface Principal {
    partner_id: string;
    partner_name: string;
  }

  interface Customer {
    partner_id: string;
    partner_name: string;
  }

  interface OrderStatus {
    status_id: string;
    status_name: string;
  }

  interface Sales {
    sales_id: string;
    sales_name: string;
  }


  const API_URL = process.env.REACT_APP_THEME_API_URL
  const GET_ORG = `${API_URL}/organization`
  const GET_INV = `${API_URL}/inventory`
  const GET_PRINCIPAL = `${API_URL}/partner/getbytype?partner_type=SUPPLIER`
  const GET_CUSTOMER = `${API_URL}/partner/getbytype?partner_type=CUSTOMER`
  const GET_ORD_STATUS = `${API_URL}/OrderStatus`
  const GET_INV_GROUP = `${API_URL}/Monitoring`
  const GET_MON_BYID = `${API_URL}/Monitoring/filter`
  const GET_INV_BYID = `${API_URL}/inventory/getbynoitem`
  const GET_SALES = `${API_URL}/Sales`
  const [companies, setCompanies] = useState<Company[]>([]);
  const [itemdataes, setItemdataes] = useState<ItemData[]>([]);
  const [principales, setPrincipales] = useState<Principal[]>([]);
  const [customers, setCustomers] = useState<Principal[]>([]);
  const [orderstatuses, setOrderStatuses] = useState<OrderStatus[]>([]);
  const [saleses, setSaleses] = useState<Sales[]>([]);

  const [selectedCompany, setSelectedCompany] = useState('');
  const [selectedItem, setSelecteditem] = useState('');
  const [selectedPrincipal, setSelectedPrincipal] = useState('');
  const [selectedCustomer, setSelectedCustomer] = useState('');
  const [selectedOrderStatus, setSelectedOrderStatus] = useState('');
  const [selectedSales, setSelectedSales] = useState('');

  const [items, setItems] = useState<Array<Item>>([]);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    date_order: '',
    order_id: '',
    partner_id: '',
    partner_name: '',
    vendor_po_no: '',
    seq_no: 0,
    no_item: '',
    item_name: '',
    customer_po: '',
    partner_company: '',
    person: '',
    promo_code: '',
    order_type: '',
    sales_user: '',
    qty_po: 0,
    bd: '',
    status: 0,
    status_po: '',
    type_trans: '',
    type_trans_order: '',
    approved_by: '',
    periode: 0,
    qty_vendor: 0,
    act_qty: 0,
    qty_receive: 0,
    updated_date: '',
    status1: 0,
    status_item: '',
    vendor_origin_date: '',
    schedule_date: '',
    progress: 0,
    position: 0,
    pos_name: '',
    prog_name: '',
    order_notes1: '',
    order_notes2: '',
    order_notes3: '',
    uom: '',
    order_price: 0,
    tax_procentage: 0,
    curr_id: '',
    rate: 0,
    subtotal: 0,
    so_number: '',
  });


  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleShowModal = (name: any) => {
    console.log(`Hello, ${name}!`);
    setShowModal(true);
  };


  useEffect(() => {
    // Handle button click event
    $('#myTable').on('click', 'button', function () {

      const iddata = this.id;
      const rowData = $(this).closest('tr').find('td').map(function () {
        return $(this).text();
      }).get();

      const rowId = $(this).closest('tr').attr('id');
      // Call your custom onClick handler with row data or rowId
      handleClick(rowData, iddata);
    });

    fetchData();
    fetchCompanies();
    fetchItemdataes();
    fetchPrincipales();
    fetchCustomers();
    fetchOrderStatuses();
    fetchSaleses();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${GET_INV_GROUP}`);
      const jsonData = response.data.data;
      setItems(jsonData);
      console.log(jsonData);
      $('#myTable').DataTable({
        info: true,
        paging: true,
        scrollX: true,
        //scrollCollapse: true,
        fixedColumns: {
          right: 2
        },
        data: jsonData,
        columns: [
          { title: 'PO Number', data: 'order_id' },
          { title: 'Order Number', data: 'vendor_po_no' },
          { title: 'No', data: 'seq_no' },
          { title: 'Item', data: 'no_item' },
          { title: 'Description', data: 'item_name' },
          { title: 'PO Number For Customer', data: 'customer_po' },
          { title: 'Customer', data: 'partner_company' },
          { title: 'BD', data: 'person' },
          { title: 'Needs', data: 'promo_code' },
          { title: 'Notes', data: 'order_notes1' },
          { title: 'Sales', data: 'sales_user' },
          { title: 'Qty (Order)', data: 'qty_po' },
          { title: 'Qty Hach', data: 'qty_vendor' },
          { title: 'Qty Real', data: 'act_qty' },
          { title: 'Date Update', data: 'updated_date' },
          { title: 'Est Date Hach', data: 'vendor_origin_date' },
          { title: 'Progress', data: 'prog_name' },
          { title: 'Position', data: 'pos_name' },
          { title: 'Status', data: null },
        ],
        columnDefs: [
          {
            targets: 18,
            render: function (data, type, full, meta) {
              if (data.status1 == 1) {

                data =
                  '<button class="btn btn-primary btn-sm btn-flat" type="button">' + data.status_item + '</i></button>';

              } else if (data.status1 == 2) {
                data =
                  '<button class="btn btn-success btn-sm btn-flat"  type="button">' + data.status_item + '</i></button>';
              } else if (data.status1 == 3) {
                data =
                  '<button class="btn btn-danger btn-sm btn-flat"  type="button">' + data.status_item + '</i></button>';
              } else if (data.status1 == 4) {
                data =
                  '<button class="btn btn-warning btn-sm btn-flat" type="button">' + data.status_item + '</i></button>';
              }
              else if (data.status1 == 5) {
                data =
                  '<button class="btn btn-info btn-sm btn-flat" type="button">' + data.status_item + '</i></button>';
              } else {
                data =
                  '<button class="btn btn-secondary btn-sm btn-flat" type="button">No Status</i></button>';
              }
              return data;
            }
          },
        ],
      });

    } catch (error) {
      //logout();
      console.error('Error fetching data:', error);
    }
  };

  const handleClick = async (rowData: any, rowId: any) => {
    console.log('Button clicked:', rowData, rowId);
    // Perform custom logic here based on the clicked row data or rowId    

    const GET_INV_BYID_DATA = `${GET_INV_BYID}?no_item=` + rowId
    const response = await axios.get(`${GET_INV_BYID_DATA}`);
    const dataSupplier = response.data;
    setFormData(dataSupplier.data);
    setShowModal(true);
  };

  const filterData = async () => {
    console.log('Button clicked!');
    // Perform any other desired actions on button click

    try {
      const org_id = selectedCompany;
      const item_no = selectedItem;
      const principal = selectedPrincipal;
      const sales = selectedSales;
      const customer = selectedCustomer;
      const status = selectedOrderStatus;
      console.log(org_id);

      const response = await axios.post(GET_MON_BYID, {
        "org_id": org_id,
        "item_no": item_no,
        "principal": principal,
        "sales": sales,
        "customer": customer,
        "status": status
      })
      const jsonData = response.data.data;
      console.log(jsonData);
      setItems(jsonData);
      const tableData = $('#myTable').DataTable();
      tableData.destroy();
      $('#myTable').DataTable({
        info: true,
        paging: true,
        pageLength: 10,
        scrollX: true,
        scrollCollapse: true,
        fixedColumns: {
          right: 1
        },
        data: jsonData,
        columns: [
          { title: 'PO Number', data: 'order_id' },
          { title: 'Order Number', data: 'vendor_po_no' },
          { title: 'No', data: 'seq_no' },
          { title: 'Item', data: 'no_item' },
          { title: 'Description', data: 'item_name' },
          { title: 'PO Number For Customer', data: 'customer_po' },
          { title: 'Customer', data: 'partner_company' },
          { title: 'BD', data: 'person' },
          { title: 'Needs', data: 'promo_code' },
          { title: 'Notes', data: 'order_notes1' },
          { title: 'Sales', data: 'sales_user' },
          { title: 'Qty (Order)', data: 'qty_po' },
          { title: 'Qty Hach', data: 'qty_vendor' },
          { title: 'Qty Real', data: 'act_qty' },
          { title: 'Date Update', data: 'updated_date' },
          { title: 'Est Date Hach', data: 'vendor_origin_date' },
          { title: 'Progress', data: 'prog_name' },
          { title: 'Position', data: 'pos_name' },
          { title: 'Status', data: null },
        ],
        columnDefs: [
          {
            targets: 18,
            render: function (data, type, full, meta) {
              if (data.status1 == 1) {

                data =
                  '<button class="btn btn-primary btn-sm btn-flat" type="button">' + data.status_item + '</i></button>';

              } else if (data.status1 == 2) {
                data =
                  '<button class="btn btn-success btn-sm btn-flat"  type="button">' + data.status_item + '</i></button>';
              } else if (data.status1 == 3) {
                data =
                  '<button class="btn btn-danger btn-sm btn-flat"  type="button">' + data.status_item + '</i></button>';
              } else if (data.status1 == 4) {
                data =
                  '<button class="btn btn-warning btn-sm btn-flat" type="button">' + data.status_item + '</i></button>';
              }
              else if (data.status1 == 5) {
                data =
                  '<button class="btn btn-info btn-sm btn-flat" type="button">' + data.status_item + '</i></button>';
              } else {
                data =
                  '<button class="btn btn-secondary btn-sm btn-flat" type="button">No Status</i></button>';
              }
              return data;
            }
          },
        ]
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchCompanies = async () => {
    try {
      // Fetch data from API
      const response = await axios.get(`${GET_ORG}`);
      const data = await response.data.data;

      // Transform API response to Company objects
      const companyData: Company[] = data.map((item: any) => ({
        org_id: item.org_id,
        org_entity: item.org_entity
      }));

      // Update the companies state with transformed data
      setCompanies(companyData);
    } catch (error) {
      console.error('Error fetching companies:', error);
    }
  };

  const fetchItemdataes = async () => {
    try {
      // Fetch data from API
      const response = await axios.get(`${GET_INV}`);
      const data = await response.data.data;

      // Transform API response to Company objects
      const itemData: ItemData[] = data.map((item: any) => ({
        no_item: item.no_item,
        item_name: item.item_name
      }));

      // Update the companies state with transformed data
      setItemdataes(itemData);
    } catch (error) {
      console.error('Error fetching Items:', error);
    }
  };

  const fetchPrincipales = async () => {
    try {
      // Fetch data from API
      const response = await axios.get(`${GET_PRINCIPAL}`);
      const data = await response.data.data;

      // Transform API response to Company objects
      const PrincipalData: Principal[] = data.map((item: any) => ({
        partner_id: item.partner_id,
        partner_name: item.partner_name
      }));

      // Update the companies state with transformed data
      setPrincipales(PrincipalData);
    } catch (error) {
      console.error('Error fetching Principal:', error);
    }
  };

  const fetchCustomers = async () => {
    try {
      // Fetch data from API
      const response = await axios.get(`${GET_CUSTOMER}`);
      const data = await response.data.data;

      // Transform API response to Company objects
      const CustomerData: Customer[] = data.map((item: any) => ({
        partner_id: item.partner_id,
        partner_name: item.partner_name
      }));

      // Update the companies state with transformed data
      setCustomers(CustomerData);
    } catch (error) {
      console.error('Error fetching Customer:', error);
    }
  };

  const fetchOrderStatuses = async () => {
    try {
      // Fetch data from API
      const response = await axios.get(`${GET_ORD_STATUS}`);
      const data = await response.data.data;

      // Transform API response to Company objects
      const StatusData: OrderStatus[] = data.map((item: any) => ({
        status_id: item.status_id,
        status_name: item.status_name
      }));

      // Update the companies state with transformed data
      setOrderStatuses(StatusData);
    } catch (error) {
      console.error('Error fetching Order Status:', error);
    }
  };

  const fetchSaleses = async () => {
    try {
      // Fetch data from API
      const response = await axios.get(`${GET_SALES}`);
      const data = await response.data.data;

      // Transform API response to Company objects
      const SalesData: Sales[] = data.map((item: any) => ({
        sales_id: item.sales_id,
        sales_name: item.sales_name
      }));

      // Update the companies state with transformed data
      setSaleses(SalesData);
    } catch (error) {
      console.error('Error fetching Sales:', error);
    }
  };

  const handleCompanyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCompany(e.target.value);
  };

  const handleItemChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelecteditem(e.target.value);
  };

  const handlePrincipalChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedPrincipal(e.target.value);
  };

  const handleCustomerChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCustomer(e.target.value);
  };

  const handleOrderStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOrderStatus(e.target.value);
  };

  const handleSalesChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedSales(e.target.value);
  };

  return (
    <div>

      <div className={`card ${className}`}>

      </div>

      <div className='row'>
        <div className='row mt-4'>
          <div className='col-md-12'>
            <div className="card card-custom">
              <div className="card-header">
                <h3 className="card-title">Monitoring & Search</h3>
              </div>
              <div className="card-body">
                <div className='row'>
                  <div className='col-lg-4'>
                    <div className="mb-4">
                      <label className="form-label">Company</label>
                      <select
                        id='org_id'
                        className="form-select"
                        aria-label="Select example"
                        value={selectedCompany}
                        onChange={handleCompanyChange}
                      >
                        <option value="">Open this select Company</option>
                        {companies.length > 0 &&
                          companies.map((company) => (
                            <option key={company.org_id} value={company.org_id}>
                              {company.org_entity}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="mb-12">
                      <label className="form-label">Item</label>
                      <select
                        className="form-select"
                        aria-label="Select example"
                        value={selectedItem}
                        onChange={handleItemChange}
                      >
                        <option value="">Open this select Item</option>
                        {itemdataes.length > 0 &&
                          itemdataes.map((company) => (
                            <option key={company.no_item} value={company.no_item}>
                              {company.item_name}
                            </option>
                          ))}
                      </select>
                    </div>

                  </div>

                  <div className='col-lg-4'>
                    <div className="mb-4">
                      <label className="form-label">Principal</label>
                      <select
                        className="form-select"
                        aria-label="Select example"
                        value={selectedPrincipal}
                        onChange={handlePrincipalChange}
                      >
                        <option value="">Open this select Principal</option>
                        {principales.length > 0 &&
                          principales.map((items) => (
                            <option key={items.partner_id} value={items.partner_id}>
                              {items.partner_name}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="mb-4">
                      <label className="form-label">Sales Name</label>
                      <select
                        className="form-select"
                        aria-label="Select example"
                        value={selectedSales}
                        onChange={handleSalesChange}
                      >
                        <option value="">Open this select Sales Name</option>
                        {saleses.length > 0 &&
                          saleses.map((items) => (
                            <option key={items.sales_id} value={items.sales_id}>
                              {items.sales_name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>

                  <div className='col-lg-4'>
                    <div className="mb-4">
                      <label className="form-label">Customer</label>
                      <select
                        className="form-select"
                        aria-label="Select example"
                        value={selectedCustomer}
                        onChange={handleCustomerChange}
                      >
                        <option value="">Open this select Customer</option>
                        {customers.length > 0 &&
                          customers.map((items) => (
                            <option key={items.partner_id} value={items.partner_id}>
                              {items.partner_name}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="mb-12">
                      <label className="form-label">Status</label>
                      <select
                        className="form-select"
                        aria-label="Select example"
                        value={selectedOrderStatus}
                        onChange={handleOrderStatusChange}
                      >
                        <option value="">Open this select Status</option>
                        {orderstatuses.length > 0 &&
                          orderstatuses.map((items) => (
                            <option key={items.status_id} value={items.status_id}>
                              {items.status_name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer">
                <button type='button' id='view' className='btn btn-primary btn-sm btn-flat' onClick={filterData}>Show</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='row mt-4'>
          <div className='col-md-12'>
            <div className="card card-custom">
              <div className="card-header">
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table id="myTable" className="table table-striped gy-7 gs-7">
                    <thead>
                      <tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
                        <th className="min-w-200px">PO Number</th>
                        <th className="min-w-100px">Order Number</th>
                        <th>No</th>
                        <th className="min-w-200px">Item</th>
                        <th className="min-w-300px">Description</th>
                        <th className="min-w-200px">PO Number For Customer</th>
                        <th>Customer</th>
                        <th>BD</th>
                        <th>Needs</th>
                        <th>Notes</th>
                        <th>Sales</th>
                        <th>Qty (Order)</th>
                        <th>Qty Hach</th>
                        <th>Qty Real</th>
                        <th className="min-w-150px">Date Update</th>
                        <th className="min-w-150px">Est Date Hach</th>
                        <th>Progress</th>
                        <th>Position</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={handleCloseModal} className='modal-lg'>
        <Modal.Header closeButton>
          <Modal.Title>Modal Title</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-6">
              <div className="mb-6">
                <label className="form-label">PO Number</label>
                <input
                  type="text"
                  className="form-control form-control-white form-control-sm"
                  placeholder="po_number"
                  name='po_number'
                  id='po_number'
                  value={formData.order_id} disabled
                />
              </div>
              <div className="mb-6">
                <label className="form-label">Order Number</label>
                <input
                  type="text"
                  className="form-control form-control-white form-control-sm"
                  placeholder="order_no"
                  name='order_no'
                  id='order_no'
                  value={formData.vendor_po_no} disabled
                />
              </div>
              <div className="mb-6">
                <label className="form-label">No</label>
                <input
                  type="text"
                  className="form-control form-control-white form-control-sm"
                  placeholder="no"
                  name='no'
                  id='no'
                  value={formData.seq_no} disabled
                />
              </div>
              <div className="mb-6">
                <label className="form-label">Item</label>
                <input
                  type="text"
                  className="form-control form-control-white form-control-sm"
                  placeholder="item_no"
                  name='item_no'
                  id='item_no'
                  value={formData.no_item} disabled
                />
              </div>
              <div className="mb-6">
                <label className="form-label">Description</label>
                <input
                  type="text"
                  className="form-control form-control-white form-control-sm"
                  placeholder="item_name"
                  name='item_name'
                  id='item_name'
                  value={formData.item_name} disabled
                />
              </div>
              <div className="mb-6">
                <label className="form-label">PO Number For Customer</label>
                <input
                  type="text"
                  className="form-control form-control-white form-control-sm"
                  placeholder="po_number_customer"
                  name='po_number_customer'
                  id='po_number_customer'
                  value={formData.customer_po} disabled
                />
              </div>
              <div className="mb-6">
                <label className="form-label">Customer</label>
                <input
                  type="text"
                  className="form-control form-control-white form-control-sm"
                  placeholder="customer"
                  name='customer'
                  id='customer'
                  value={formData.partner_company} disabled
                />
              </div>
              <div className="mb-6">
                <label className="form-label">BD</label>
                <input
                  type="text"
                  className="form-control form-control-white form-control-sm"
                  placeholder="bd"
                  name='bd'
                  id='bd'
                  value={formData.person} disabled
                />
              </div>
              <div className="mb-6">
                <label className="form-label">Needs</label>
                <input
                  type="text"
                  className="form-control form-control-white form-control-sm"
                  placeholder="needs"
                  name='needs'
                  id='needs'
                  value={formData.promo_code} disabled
                />
              </div>
              <div className="mb-6">
                <label className="form-label">Notes</label>
                <input
                  type="text"
                  className="form-control form-control-white form-control-sm"
                  placeholder="notes"
                  name='notes'
                  id='notes'
                  value={formData.order_notes1} disabled
                />
              </div>
              <div className="mb-6">
                <label className="form-label">Sales</label>
                <input
                  type="text"
                  className="form-control form-control-white form-control-sm"
                  placeholder="sales_name"
                  name='sales_name'
                  id='sales_name'
                  value={formData.sales_user} disabled
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="mb-6">
                <label className="form-label">Qty Order</label>
                <input
                  type="text"
                  className="form-control form-control-white form-control-sm"
                  placeholder="qty_order"
                  name='qty_order'
                  id='qty_order'
                  value={formData.qty_po} disabled
                />
              </div><div className="mb-6">
                <label className="form-label">Qty Hach</label>
                <input
                  type="text"
                  className="form-control form-control-white form-control-sm"
                  placeholder="qty_hach"
                  name='qty_hach'
                  id='qty_hach'
                  value={formData.qty_vendor} disabled
                />
              </div><div className="mb-6">
                <label className="form-label">Qty Real</label>
                <input
                  type="text"
                  className="form-control form-control-white form-control-sm"
                  placeholder="qty_actual"
                  name='qty_actual'
                  id='qty_actual'
                  value={formData.act_qty} disabled
                />
              </div><div className="mb-6">
                <label className="form-label">Date Update</label>
                <input
                  type="text"
                  className="form-control form-control-white form-control-sm"
                  placeholder="updated_at"
                  name='updated_at'
                  id='updated_at'
                  value={formData.updated_date} disabled
                />
              </div><div className="mb-6">
                <label className="form-label">Origin Est Date Hach</label>
                <input
                  type="text"
                  className="form-control form-control-white form-control-sm"
                  placeholder="origin_est_date_hach"
                  name='origin_est_date_hach'
                  id='origin_est_date_hach'
                  value={formData.vendor_origin_date} disabled
                />
              </div>
              <div className="mb-6">
                <label className="form-label">Each Date Hach</label>
                <input
                  type="text"
                  className="form-control form-control-white form-control-sm"
                  placeholder="est_date_hach"
                  name='est_date_hach'
                  id='est_date_hach'
                  value={formData.vendor_origin_date} disabled
                />
              </div>
              <div className="mb-6">
                <label className="form-label">Progress</label>
                <input
                  type="text"
                  className="form-control form-control-white form-control-sm"
                  placeholder="Progress"
                  name='prog_name'
                  id='prog_name'
                  value={formData.prog_name} disabled
                />
              </div>
              <div className="mb-6">
                <label className="form-label">Position</label>
                <input
                  type="text"
                  className="form-control form-control-white form-control-sm"
                  placeholder="Position"
                  name='pos_name'
                  id='pos_name'
                  value={formData.pos_name} disabled
                />
              </div>
              <div className="mb-6">
                <label className="form-label">Status</label>
                <select id='status'
                  className="form-select form-select-white form-select-sm"
                  aria-label="Status"
                  value={selectedOrderStatus}
                  onChange={handleOrderStatusChange}
                >
                  <option value={formData.status1} >{formData.status_item}</option>
                  {orderstatuses.length > 0 &&
                    orderstatuses.map((items) => (
                      <option key={items.status_id} value={items.status_id}>
                        {items.status_name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="mb-6">
                <label className="form-label">Notes</label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid"
                  placeholder="Notes"
                  name='notes1'
                  id='notes1'
                />
              </div>

            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          {/* <Button variant="primary" onClick={handleCloseModal}>
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export { MonitoringPage }
