import React, { useState, useEffect } from 'react';
import axios from 'axios';
import $ from 'jquery';
import 'datatables.net-dt';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import 'datatables.net-fixedcolumns'

type Props = {
  className: string
}

const UserPage: React.FC<Props> = ({ className }) => {


  interface Item {
    no_group: string;
    group_name: string;
    status: number;
    no_account: number;
    group_account: number;
    group_type: string;
    type_sales: number;
    wip_account: number;
    default_fg: boolean;
    default_rm: boolean;
    default_wip: boolean;
    jenis: string;
  }

  const columnsx = [
    {
      name: 'No Group',
      selector: (row: { no_group: any; }) => row.no_group,
    },
    {
      name: 'Group Name',
      selector: (row: { group_name: any; }) => row.group_name,
    },
    {
      name: 'Status',
      selector: (row: { status: any; }) => row.status,
    },
    {
      name: 'No Account',
      selector: (row: { no_account: any; }) => row.no_account,
    },
    {
      name: 'Group Account',
      selector: (row: { group_account: any; }) => row.group_account,
    },
    {
      name: 'Group Type',
      selector: (row: { group_type: any; }) => row.group_type,
    },
    {
      name: 'Type Sales',
      selector: (row: { type_sales: any; }) => row.type_sales,
    },
    {
      name: 'WIP Account',
      selector: (row: { wip_account: any; }) => row.wip_account,
    },
    {
      name: 'Default FG',
      selector: (row: { default_fg: any; }) => row.default_fg,
    },
    {
      name: 'Default RM',
      selector: (row: { default_rm: any; }) => row.default_rm,
    },
    {
      name: 'Default WIP',
      selector: (row: { default_wip: any; }) => row.default_wip,
    },
    {
      name: 'Jenis',
      selector: (row: { jenis: any; }) => row.jenis,
    },
  ];

  const API_URL = process.env.REACT_APP_THEME_API_URL
  const GET_INV_GROUP = `${API_URL}/user`
  const [items, setItems] = useState<Array<Item>>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${GET_INV_GROUP}`);
        const jsonData = response.data.data;
        setItems(jsonData);
        $('#myTable').DataTable({
          data: jsonData,
          columns: [
            { title: 'Username', data: 'username' },
            { title: 'FullName', data: 'fullname' },
            { title: 'Email', data: 'email' },
            { title: 'Organization Id', data: 'org_id' },
            { title: 'Action', data: null },
          ],
          info: true,
          paging: true,
          pageLength: 15,
          scrollCollapse: true,
          fixedColumns: {
            left: 1
          },
          columnDefs: [
            {
              targets: 4,
              render: function (data, type, full, meta) {
                if (type === 'display') {
                  data =
                    '<button class="btn btn-info btn-sm" data-dismiss="modal" id="Edit" type="button">Show</button>';
                }
                return data;
              }
            }
          ]
        });

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}      
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* <div>
          <DataTable
            data={items}
            columns={columnsx}
            pagination

            // custom styles
            style={{
              backgroundColor: 'white',
              borderRadius: '4px',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            }}
            // custom row styles
            customStyles={{
              rows: {
                style: {
                  minHeight: '48px', // set minimum height for rows
                },
              },
              headRow: {
                style: {
                  borderTopStyle: 'solid',
                  borderTopWidth: '1px',
                  borderTopColor: '#e0e0e0', // set custom border color for header row
                },
              },
            }}
          // ... other props and customization options
          />
        </div> */}

        <div>
          <table id="myTable" className="table table-hover table-striped table-bordered">
            <thead>
              <tr>
                <th>Username</th>
                <th>Full Name</th>
                <th>Email</th>
                <th>Organization Id</th>
                <th>Action</th>
              </tr>
            </thead>
          </table>
        </div>

      </div>
      {/* begin::Body */}


    </div>


  )
}

export { UserPage }
