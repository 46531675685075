/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { useIntl } from 'react-intl'
import { SidebarMenuItem } from './SidebarMenuItem'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'

const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>MENU</span>
        </div>
      </div>
      <SidebarMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
        fontIcon='bi-app-indicator'
      />

      <SidebarMenuItemWithSub
        to='/crafted/pages'
        title='Master Data'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >

        <SidebarMenuItem
          to='/mastercustomer'
          icon='/media/icons/duotune/communication/com013.svg'
          title='Master Customer'
          fontIcon='bi-layers'
        />

        <SidebarMenuItem
          to='/mastersupplier'
          icon='/media/icons/duotune/ecommerce/ecm004.svg'
          title='Master Supplier'
          fontIcon='bi-layers'
        />

        <SidebarMenuItem
          to='/masteritem'
          icon='/media/icons/duotune/general/gen019.svg'
          title='Master Inventory'
          fontIcon='bi-layers'
        />

        {/* <SidebarMenuItem
          to='/categoryitem'
          icon='/media/icons/duotune/general/gen019.svg'
          title='Inventory Group'
          fontIcon='bi-layers'
        /> */}
      </SidebarMenuItemWithSub>

      <SidebarMenuItem
        to='/purchaserequest'
        icon='/media/icons/duotune/ecommerce/ecm002.svg'
        title='Purchase Request'
        fontIcon='bi-layers'
      />

      <SidebarMenuItem
        to='/purchaseorder'
        icon='/media/icons/duotune/finance/fin006.svg'
        title='Purchase Order'
        fontIcon='bi-layers'
      />

      <SidebarMenuItem
        to='/goodreceived'
        icon='/media/icons/duotune/general/gen048.svg'
        title='Good Received'
        fontIcon='bi-layers'
      />


      <SidebarMenuItem
        to='/updatestatus'
        icon='/media/icons/duotune/coding/cod001.svg'
        title='Update Status By Stage'
        fontIcon='bi-layers'
      />

      <SidebarMenuItem
        to='/monitoring'
        icon='/media/icons/duotune/graphs/gra008.svg'
        title='Monitoring and Search'
        fontIcon='bi-layers'
      />

      <SidebarMenuItemWithSub
        to='/crafted/pages'
        title='User Management'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen051.svg'
      >

        <SidebarMenuItem
          to='/usercore'
          icon='/media/icons/duotune/communication/com013.svg'
          title='User Core'
          fontIcon='bi-layers'
        />

        {/* <SidebarMenuItem
          to='/role'
          icon='/media/icons/duotune/communication/com013.svg'
          title='Role'
          fontIcon='bi-layers'
        />

        <SidebarMenuItem
          to='/permission'
          icon='/media/icons/duotune/communication/com013.svg'
          title='Permission'
          fontIcon='bi-layers'
        />

        <SidebarMenuItem
          to='/userpemission'
          icon='/media/icons/duotune/general/gen019.svg'
          title='User Permission'
          fontIcon='bi-layers'
        />

        <SidebarMenuItem
          to='/userrole'
          icon='/media/icons/duotune/general/gen019.svg'
          title='User Role'
          fontIcon='bi-layers'
        />

        <SidebarMenuItem
          to='/menu'
          icon='/media/icons/duotune/ecommerce/ecm004.svg'
          title='Menu'
          fontIcon='bi-layers'
        />

        <SidebarMenuItem
          to='/menurole'
          icon='/media/icons/duotune/ecommerce/ecm004.svg'
          title='Menu Role'
          fontIcon='bi-layers'
        /> */}
      </SidebarMenuItemWithSub>
    </>
  )
}

export { SidebarMenuMain }
