import React, { useState, useEffect } from 'react';
import axios from 'axios';
import $ from 'jquery';
import 'datatables.net-dt';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import 'datatables.net-fixedcolumns'
import { object } from 'yup';

type Props = {
  className: string
}

const CategoryPage: React.FC<Props> = ({ className }) => {


  interface Item {
    no_group: string;
    group_name: string;
    status: number;
    no_account: number;
    group_account: number;
    group_type: string;
    type_sales: number;
    wip_account: number;
    default_fg: boolean;
    default_rm: boolean;
    default_wip: boolean;
    jenis: string;
  }


  const API_URL = process.env.REACT_APP_THEME_API_URL
  const GET_INV_GROUP = `${API_URL}/inventory/group`
  const [items, setItems] = useState<Array<Item>>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {        
        // const token = sessionStorage.getItem('token');
        // console.log(token)
        // var settings = {
        //   "url": GET_INV_GROUP,
        //   "method": "GET",
        //   "timeout": 0,
        //   "headers": {
        //     "Authorization": `Bearer ${token}`
        //   },
        // };
        const response = await axios.get(`${GET_INV_GROUP}`);
        const jsonData = response.data.data;
        setItems(jsonData);
        $('#myTable').DataTable({
          info: true,
          paging: true,
          pageLength: 15,
          scrollCollapse: true,
          fixedColumns: {
            left: 1
          },
          data: jsonData,
          columns: [
            { title: 'No Group', data: 'no_group' },
            { title: 'Group Name', data: 'group_name' },
            { title: 'Status', data: 'status' },
            { title: 'No Account', data: 'no_account' },
            { title: 'Group Account', data: 'group_account' },
            { title: 'Group Type', data: 'group_type' },
            { title: 'Type Sales', data: 'type_sales' },
            { title: 'WIP Account', data: 'wip_account' },
            { title: 'Default FG', data: 'default_fg' },
            { title: 'Default RM', data: 'default_rm' },
            { title: 'Default WIP', data: 'default_wip' },
            { title: 'Jenis', data: 'jenis' },
            { title: 'Action', data: null },
          ],            
          columnDefs: [
            {
              targets: 12,
              render: function (data, type, full, meta) {
                if (type === 'display') {
                  data =
                    '<button class="btn btn-info btn-sm" data-dismiss="modal" id="Edit" type="button">Show</button>';
                }
                return data;
              }
            }
          ]
        });

        // $.ajax(settings).done(function (response) {          
        //   var jsonData = response.data;
          
        // });

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Group Inventory</span>
        </h3>
        <div className='card-toolbar'>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* <div>
          <DataTable
            data={items}
            columns={columnsx}
            pagination

            // custom styles
            style={{
              backgroundColor: 'white',
              borderRadius: '4px',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            }}
            // custom row styles
            customStyles={{
              rows: {
                style: {
                  minHeight: '48px', // set minimum height for rows
                },
              },
              headRow: {
                style: {
                  borderTopStyle: 'solid',
                  borderTopWidth: '1px',
                  borderTopColor: '#e0e0e0', // set custom border color for header row
                },
              },
            }}
          // ... other props and customization options
          />
        </div> */}

        <div>
          <table id="myTable" className="table table-hover table-striped table-bordered">
            <thead>
              <tr>
                <th>No Group</th>
                <th>Group Name</th>
                <th>Status</th>
                <th>No Account</th>
                <th>Group Account</th>
                <th>Group Type</th>
                <th>Type Sales</th>
                <th>WIP Account</th>
                <th>Default FG</th>
                <th>Default RM</th>
                <th>Default WIP</th>
                <th>Jenis</th>
                <th>Action</th>
              </tr>
            </thead>
            {/* <tbody>
              {items.map((result) => {
                return (

                  <tr>
                    <td>{result.no_group}</td>
                    <td>{result.group_name}</td>
                    <td>{result.status}</td>
                    <td>{result.no_account}</td>
                    <td>{result.group_account}</td>
                    <td>{result.group_type}</td>
                    <td>{result.type_sales}</td>
                    <td>{result.wip_account}</td>
                    <td>{result.default_fg}</td>
                    <td>{result.default_rm}</td>
                    <td>{result.default_wip}</td>
                    <td>{result.jenis}</td>
                    <td>{result.default_wip}</td>
                  </tr>

                )
              })}
            </tbody> */}
          </table>
        </div>

      </div>
      {/* begin::Body */}


    </div>


  )
}

export { CategoryPage }
